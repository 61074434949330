import * as Sentry from "@sentry/react";

/**
 * Replaces placeholders in the format {{var:variableName}} within the input string
 * using values from the provided context dictionary.
 *
 * @param input - The input string containing placeholders.
 * @param context - An object containing key-value pairs for substitution.
 * @returns The input string with all placeholders substituted with corresponding context values.
 */
export function substituteVariables(
  input: string,
  context: { [key: string]: any }
): string {
  // TODO: Add ability to reference form field values (including . dot nested values)
  // Regular expression to match {{var:variableName}} patterns
  const variablePattern = /{{\s*var\s*:\s*([a-zA-Z0-9_]+)\s*}}/g;

  return input.replace(variablePattern, (match, variableName) => {
    // Check if the variable exists in the context
    if (variableName in context) {
      return String(context[variableName]);
    } else {
      Sentry.captureMessage(
        `Form variable substitution missing substitute: ${variableName}`
      );

      // If variable not found, return with placeholder
      console.warn(`Variable "${variableName}" not found in context.`);
      // NOTE: Or is it better to leave variable?
      return "____";
    }
  });
}
