import { useOutletContext } from "react-router-dom";
import { useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { PopupModal } from "react-calendly";

import config from "config";
import Checkmark from "svg/checkmark.svg";
import Sparkle from "svg/sparkle.svg";

import { useSafeState } from "hooks/useSafeState";
import { useGET } from "hooks/usePublicAPI";
import useAnalytics from "hooks/useAnalytics";

import { useFacebookPixel } from "components/FacebookPixelProvider";
import ExpandableContent from "components/ExpandableContent";
import Loading from "components/Loading";
import Reviews from "components/Reviews";
import {
  OnboardingContainer,
  OnboardingContent,
  OnboardingPanel,
  OnboardingPanelUnder,
  OnboardingHeader,
} from "layouts/OnboardingLayout";
import { QuoteInfo, PLANS } from "constants/plans";

const global = { phone_pre_need: "+18444150199" }; // TODO: Move to config
const STATES: Record<string, string> = {
  az: "Arizona",
  ca: "California",
  co: "Colorado",
  ut: "Utah",
  wa: "Washington",
  or: "Oregon",
  nv: "Nevada",
  tx: "Texas",
  fl: "Florida",
};

function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return "";
  }
  return `(${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
    5,
    8
  )}-${phoneNumber.slice(8)}`;
}

// ===========================================================
// Helpers for Calendly
// ===========================================================

const readCookie = (name: string) => {
  const nameKey = name + "=";
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((cookieName) =>
    cookieName.trim().startsWith(nameKey)
  );

  if (cookie !== undefined && typeof cookie === "string") {
    return cookie.trim().replace(nameKey, "");
  }

  return null;
};

function getDataPayload(quoteInfo: any) {
  const { event_id, state } = quoteInfo;

  const payload = {
    state,
    event_id,
    fbp: readCookie("_fbp"),
    fbc: readCookie("_fbc"),
    user_agent: navigator.userAgent,
    browser_url: window.location.href,
  };
  return btoa(JSON.stringify(payload));
}

// ===========================================================
// Helpers for Quote Display
// ===========================================================

function getPaymentOptions(quote: any) {
  return quote.options.map((option: any) => ({
    id: option.name,
    label: option.price,
    subtext: option.subtext,
  }));
}

// ===========================================================
// Calendly Embed Modal
// ===========================================================

function CalendlyEmbedModal({
  quoteInfo,
  buttonContent,
}: {
  quoteInfo: QuoteInfo;
  buttonContent: React.ReactNode;
}) {
  const analytics = useAnalytics();
  const [isOpen, setIsOpen] = useSafeState(false);
  const { trackEvent } = useFacebookPixel();

  // Handle resizing of the Calendly iframe when a time/date is selected and the content
  // gets taller
  useEffect(() => {
    const handleEvent = (event: any) => {
      if (event.origin !== "https://calendly.com") {
        return;
      }

      switch (event.data.event) {
        case "calendly.event_type_viewed":
          analytics.logEvent("pre_need_policy_call_type_viewed");
          Sentry.addBreadcrumb({
            category: "calendly.event_type_viewed",
            message: "Calendly event type viewed",
            level: "info",
          });
          break;

        case "calendly.date_and_time_selected":
          analytics.logEvent("pre_need_policy_call_date_and_time_selected");
          Sentry.addBreadcrumb({
            category: "calendly.date_and_time_selected",
            message: "Calendly date and time selected",
            level: "info",
          });
          break;

        case "calendly.event_scheduled":
          trackEvent({
            event: "Schedule",
            eventId: quoteInfo.event_id,
            data: {
              content_name: "pre-need-lead",
              content_category: "pre-need",
              state: quoteInfo.state,
              package: quoteInfo.plan,
            },
          });
          analytics.logEvent("pre_need_policy_call_scheduled");
          Sentry.addBreadcrumb({
            category: "calendly.event_scheduled",
            message: "Calendly event scheduled",
            level: "info",
          });
          Sentry.setTag("quote.scheduled_call", "true");
          break;
      }
    };

    const listener = window.addEventListener("message", handleEvent);

    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, []);

  function handleClick() {
    analytics.logEvent("cta_button_click", {
      id: "schedule_a_call",
      cta_button_id: "schedule_a_call",
    });
    setIsOpen(true);
  }

  return (
    <div>
      <button
        className="px-4 py-3 bg-cantelope rounded-xl text-soil outline outline-cantelope outline-2 hover:bg-soil hover:text-cantelope transition-all"
        onClick={handleClick}
        id="cta_button_schedule_a_call"
      >
        {buttonContent}
      </button>
      <PopupModal
        url="https://calendly.com/after-pre-planning/start-pre-planning-portal"
        open={isOpen}
        onModalClose={() => setIsOpen(false)}
        rootElement={document.getElementById("root") as HTMLElement}
        prefill={{
          email: quoteInfo.email,
          name: quoteInfo.full_name,
          smsReminderNumber: quoteInfo.phone || "",
          customAnswers: {
            a1: quoteInfo.phone || "",
          },
        }}
        pageSettings={{
          hideGdprBanner: true,
        }}
        utm={{
          salesforce_uuid: getDataPayload(quoteInfo),
        }}
      />
    </div>
  );
}

function QuestionsCallUs() {
  const analytics = useAnalytics();

  function logEvent() {
    analytics.logEvent("link_click", {
      id: "questions_call_us",
      link_id: "questions_call_us",
    });
  }

  return (
    <div className="my-6 text-center">
      <div className="font-serif mb-2">
        Questions? Talk to a{" "}
        <span className="inline-block">pre-planning expert</span>
      </div>
      <a
        href={`tel:${global.phone_pre_need}`}
        className="underline underline-offset-4"
        onClick={logEvent}
      >
        {formatPhoneNumber(global.phone_pre_need)}
      </a>
    </div>
  );
}

// ===========================================================
// Next Steps
// ===========================================================

function detectIsOpen() {
  const CALLING_HOUR_OPEN = 8;
  const CALLING_HOUR_CLOSED = 18;

  const currentDate = new Date();
  const currentHourString = currentDate.toLocaleString("en-US", {
    timeZone: "America/Denver",
    hour: "numeric",
    hour12: false,
  });
  const currentDayOfWeek = currentDate.toLocaleString("en-US", {
    timeZone: "America/Denver",
    weekday: "narrow",
  });
  const currentHour = parseInt(currentHourString, 10);

  const isOpenHour = (hour: number) =>
    hour >= CALLING_HOUR_OPEN && hour < CALLING_HOUR_CLOSED;
  const isOpenDay = (dayOfWeek: string) => dayOfWeek !== "S";
  const isOpen = isOpenHour(currentHour) && isOpenDay(currentDayOfWeek);

  return isOpen;
}

interface NextStepsProps {
  quoteInfo: QuoteInfo;
}

function NextSteps({ quoteInfo }: NextStepsProps) {
  const analytics = useAnalytics();
  const [isBusinessHours, setIsBusinessHours] = useSafeState(true);

  useEffect(() => {
    function updateBusinessHours() {
      const isOpen = detectIsOpen();
      Sentry.setTag("business_hours", isOpen ? "open" : "closed");

      setIsBusinessHours(isOpen);
    }

    function handleVisibilityChange() {
      if (document.visibilityState !== "visible") {
        return;
      }

      updateBusinessHours();
    }

    updateBusinessHours();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visiblitychange", handleVisibilityChange);
    };
  }, []);

  function logEvent() {
    analytics.logEvent("cta_button_click", {
      id: "call_us_now",
      cta_button_id: "call_us_now",
    });
  }

  return (
    <div className="flex flex-wrap space-evenly pt-6 pb-8 px-4 bg-soil text-white rounded-xl items-center">
      <div className="w-full text-center mb-4 font-serif">
        <h1 className="underline underline-offset-4">Next Steps</h1>
      </div>

      {/* TODO: Allow continue online */}
      {false && (
        <>
          <div className="text-center sm:flex-1 grow shrink-0">
            <h2 className="mb-2 font-serif">Select a Payment Plan</h2>

            <p>And continue online</p>
          </div>

          <div className="font-serif italic text-2xl sm:flex-none grow text-center py-4 w-full sm:w-min">
            Or
          </div>
        </>
      )}

      <div className="text-center sm:flex-1 grow sm:shrink-0">
        <h2 className="mb-2 font-serif text-balance sm:px-0">
          Speak with a licensed{" "}
          <span className="inline-block">pre-planning agent</span>
        </h2>

        <div className="flex space-around w-full mt-4 flex-wrap sm:flex-nowrap space-y-4 sm:space-y-0">
          {isBusinessHours && (
            <div className="w-full">
              <h3 className="mb-2">
                <span className="relative">
                  Call us Now
                  <div
                    className="flex items-center absolute -top-0.5 -right-2.5"
                    title="Online now"
                  >
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                    </span>
                  </div>
                </span>
              </h3>

              <a href={`tel:${global.phone_pre_need}`} onClick={logEvent}>
                <button
                  className="px-4 py-3 bg-cantelope rounded-xl text-soil outline outline-cantelope outline-2 hover:bg-soil hover:text-cantelope transition-all"
                  id="cta_button_call_us_now"
                >
                  {formatPhoneNumber(global.phone_pre_need)}
                </button>
              </a>
            </div>
          )}

          <div className="w-full">
            <h3 className="mb-2">Schedule a Call</h3>

            <CalendlyEmbedModal
              quoteInfo={quoteInfo}
              buttonContent={<span>Pick a date and time</span>}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// ===========================================================
// Package Benefits
// ===========================================================

function PackageBenefits({
  benefits,
  isLoading,
}: {
  benefits: Array<Record<string, unknown>>;
  isLoading?: boolean;
}) {
  return (
    <div>
      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
        {benefits.map((benefit: any, index: number) => (
          <li
            key={benefit.label || index}
            className="flex flex-col space-y-2 sm:space-y-2 mt-2 bg-oat rounded-xl p-4"
          >
            {!isLoading && <span className="font-bold">{benefit.label}</span>}
            {isLoading && (
              <div className="animated-background h-5 w-full"></div>
            )}

            {benefit.blurbs &&
              benefit.blurbs.map((blurb: string) => (
                <div className="text-sm" key={blurb}>
                  {blurb}
                </div>
              ))}
          </li>
        ))}
      </ul>
    </div>
  );
}

// ===========================================================
// Quote Information
// ===========================================================

function QuoteInformation({ quoteInfo }: { quoteInfo: QuoteInfo }) {
  return (
    <div
      className="rounded-xl bg-oat px-4 sm:px-6 py-5 sm:py-6"
      data-sentry-mask
    >
      <div className="border-b border-b-soil mb-4">
        <h2 className="font-serif mb-3">Your Information</h2>
      </div>

      <div className="flex flex-col space-y-2">
        <div>
          <span className="font-bold inline-block mr-3">Purchaser:</span>
          {quoteInfo.full_name}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Recipient:</span>
          {quoteInfo.for_self ? "Me" : quoteInfo.insured_full_name}
        </div>

        {quoteInfo.hq1 && (
          <div>
            <span className="font-bold inline-block mr-3">
              Recipient terminal illness:
            </span>
            Yes
          </div>
        )}

        <div>
          <span className="font-bold inline-block mr-3">
            Recipient Date of Birth:
          </span>
          {quoteInfo.insured_dob}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Email:</span>
          {quoteInfo.email}
        </div>

        <div>
          <span className="font-bold inline-block mr-3">Phone:</span>
          {formatPhoneNumber(quoteInfo.phone) || "N/A"}
        </div>
      </div>
    </div>
  );
}

// ===========================================================
// Package Quote
// ===========================================================

function PackageQuotePrice({
  payment_period_oap,
  oap_total,
  payment_period,
  price,
  isLoading,
  isActive,
  onClick,
}: {
  payment_period_oap?: string;
  oap_total?: number;
  payment_period: string;
  price: string;
  isLoading?: boolean;
  isActive?: boolean;
  onClick: (price: any) => void;
}) {
  return (
    <div
      className="relative w-full h-full sm:flex-1 border border-2 border-oat rounded-xl p-4 flex items-center justify-self-center self-end justify-center data-[active=true]:border-cantelope transition"
      data-active={isActive}
      onClick={() => onClick(price)}
    >
      <div className="text-center">
        {payment_period_oap === "single_pay" && oap_total > 0 && (
          <>
            <div className="absolute -top-2 sm:-right-0 -right-2 rounded-full bg-cantelope p-1 w-5 h-5 z-20">
              <Sparkle className="fill-none stroke-white" />
            </div>
            <div className="italic">${oap_total} down payment</div>
          </>
        )}

        {isLoading && (
          <div className="text-2xl mb-2 animated-background h-8"></div>
        )}
        {!isLoading && <div className="text-2xl mb-2">{price}</div>}

        <div>
          {payment_period === "single_pay"
            ? "Pay total"
            : "Pay monthly for 3 years"}
        </div>
      </div>
    </div>
  );
}

type TimeOutParams = {
  timeLimit?: number;
  reportTimeOut?: boolean;
  timeOutId?: string;
};

function useTimeOut({
  timeLimit = 30000,
  timeOutId,
  reportTimeOut,
}: TimeOutParams = {}) {
  const analytics = useAnalytics();
  const [hasTimedOut, setHasTimedOut] = useSafeState(false);
  const [timeoutCancelled, setTimeoutCancelled] = useSafeState(false);
  const timeout = useRef(0);

  useEffect(() => {
    if (!timeoutCancelled) {
      timeout.current = window.setTimeout(
        () => {
          setHasTimedOut(true);

          if (reportTimeOut) {
            analytics.logEvent("time_out_reached", {
              time_out_id: timeOutId,
            });
            Sentry.addBreadcrumb({
              category: "timeout.reached",
              message: ["Time out reached", timeOutId]
                .filter((s) => !s)
                .join(": "),
              level: "warning",
            });
          }
        },
        timeLimit,
        timeOutId,
        reportTimeOut
      );
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [timeLimit]);

  function cancelTimeout() {
    clearTimeout(timeout.current);
    setHasTimedOut(false);
    setTimeoutCancelled(true);
  }

  return [hasTimedOut, timeout, cancelTimeout] as const;
}

function PackageQuote({
  name,
  plan,
  quote,
  showBenefits,
  onClick,
}: {
  name: string;
  plan: any;
  quote: any;
  showBenefits?: boolean;
  onClick: () => void;
}) {
  const analytics = useAnalytics();
  const [hasTimedOut, timeout, cancelTimeout] = useTimeOut({
    reportTimeOut: true,
    timeOutId: "package_quote",
  });
  const [activePrice, setActivePrice] = useSafeState();

  useEffect(() => {
    if (plan && timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = 0;
    }

    // Clear the active price when the plan changes
    setActivePrice(undefined);
  }, [plan]);

  useEffect(() => {
    if (quote && timeout.current) {
      cancelTimeout();
    }
  }, [quote]);

  const multiPayOptions = !quote
    ? []
    : quote.options.filter(
        (option: any) => option.payment_period === "pay_three"
      );
  const multiPay =
    multiPayOptions.length === 1
      ? multiPayOptions[0]
      : multiPayOptions.find(
          (option: any) => option.payment_period_oap === "single_pay"
        );

  // Base option only has one other payment option
  const loadingOptions =
    name === "Base"
      ? [{ payment_period: "pay_three", id: "a" }]
      : [
          { payment_period: "pay_three", id: "a" },
          { payment_period: "pay_three", id: "b" },
        ];

  function logEvent() {
    analytics.logEvent("expand_content", {
      id: "view_plan_benefits",
      content_id: "view_plan_benefits",
      segment: "pre_need",
      plan: name,
    });
  }

  function handlePriceClick(price: any) {
    setActivePrice(price);
    onClick();
  }

  return (
    <div className="px-4 pt-2 sm:px-6 py-5 sm:py-6 sm:pt-3 border-oat border-l-2 border-r-2 border-b-2 rounded-b-xl w-full">
      <header className="flex justify-between items-start flex-wrap">
        <div className="flex mb-2 text-l items-baseline">
          <div className="font-bold mr-1 text-xl mr-2">{name}</div>
          <div className="font-serif italic font-bold text-evergreen">
            package
          </div>
        </div>
      </header>

      <div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <PackageQuotePrice
            price={plan && `$${plan.list_price}`}
            payment_period="single_pay"
            isLoading={!plan}
            isActive={`$${plan?.list_price}` === activePrice}
            onClick={handlePriceClick}
          />

          {quote &&
            quote.options
              .filter((option: any) => option.payment_period !== "single_pay")
              .map((option: any) => (
                <PackageQuotePrice
                  price={option.price}
                  oap_total={plan?.oap_total}
                  payment_period_oap={option.payment_period_oap}
                  payment_period={option.payment_period}
                  onClick={handlePriceClick}
                  isActive={option.price === activePrice}
                  key={option.price}
                />
              ))}

          {!quote &&
            !hasTimedOut &&
            loadingOptions.map((option: any) => (
              <PackageQuotePrice
                price={option.price}
                oap_total={plan?.oap_total}
                payment_period_oap={option.payment_period_oap}
                payment_period={option.payment_period}
                onClick={handlePriceClick}
                isLoading
                key={option.id}
              />
            ))}

          {!quote && hasTimedOut && (
            <div className="w-full h-full text-center border border-2 border-soil rounded-xl p-4 flex items-center justify-self-center self-end justify-center sm:col-span-2">
              <strong>Your quote has been sent to your email!</strong>
            </div>
          )}
        </div>

        {name === "Base" && (
          <div className="text-sm mt-2 -mb-1 italic">
            Does not include price-lock guarantee
          </div>
        )}
      </div>

      {showBenefits && (
        <div className="mt-2 sm:mt-4">
          <ExpandableContent
            header={
              <strong className="text-sm">
                {plan.whats_included_text
                  ? `Benefits – ${plan.whats_included_text}`
                  : `Benefits Include`}
              </strong>
            }
            onToggle={logEvent}
          >
            <PackageBenefits benefits={plan.features} />
          </ExpandableContent>
        </div>
      )}
    </div>
  );
}

const PLAN_OPTIONS = [
  {
    name: "Base",
    id: "simple",
  },
  {
    name: "Crest",
    id: "value",
  },
  {
    name: "Summit",
    id: "choice",
  },
];

function MainPackageQuote({ quotes, quoteInfo, plans }: any) {
  const analytics = useAnalytics();
  const [currentPlan, setCurrentPlan] = useSafeState(quoteInfo.plan);
  const [highlightNextSteps, setHighlight] = useSafeState(false);
  const nextStepsRef = useRef<HTMLDivElement>(null);
  const name = PLANS[currentPlan];
  const plan = plans && plans.find((p: any) => name === p.name);
  const quote =
    quotes && quotes.find((q: any) => q.name === PLANS[currentPlan]);

  useEffect(() => {
    const handleScroll = () => {
      if (highlightNextSteps) {
        setHighlight(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [highlightNextSteps]);

  function handleClick(planId: string) {
    analytics.logEvent("plan_toggle_click", {
      plan_prev: currentPlan,
      plan_next: planId,
    });
    setCurrentPlan(planId);
  }

  function handlePaymentClick() {
    nextStepsRef.current?.scrollIntoView();
    window.setTimeout(() => {
      setHighlight(true);
    }, 750);
  }

  return (
    <div>
      <div className="space-y-4 sm:space-y-6">
        <div className="-mb-4">
          <div className="border-t-2 border-l-2 border-r-2 border-oat rounded-t-xl flex w-full">
            {PLAN_OPTIONS.map((p: any) => (
              <button
                onClick={() => handleClick(p.id)}
                className={`
                -mt-[2px]
                rounded-none
                basis-1/3
                group
                grow
                relative
                border-b-2
                border-oat
                py-3
                first:rounded-tl-xl
                last:rounded-tr-xl
                data-[active=true]:bg-oat
                data-[active=true]:border-solid
                data-[active=true]:border-soil
                `}
                key={p.name}
                data-active={p.id === currentPlan}
                data-plan={p.id}
              >
                {p.name}
                <div className="hidden group-data-[plan=value]:block absolute -top-2 sm:-right-0 -right-2 rounded-full bg-cantelope p-1 w-5 h-5 z-20">
                  <Sparkle className="fill-none stroke-white" />
                </div>
              </button>
            ))}
          </div>

          <PackageQuote
            name={name}
            plan={plan}
            quote={quote}
            onClick={handlePaymentClick}
          />
        </div>

        <div className="pt-4" ref={nextStepsRef}>
          <div
            className="data-[highlight=true]:shadow-lg data-[highlight=true]:shadow-cantelope transition rounded-xl"
            data-highlight={highlightNextSteps}
          >
            <NextSteps quoteInfo={quoteInfo} />
          </div>
        </div>

        <MainPackageBenefits
          quoteInfo={quoteInfo}
          currentPlan={currentPlan}
          plans={plans}
        />
      </div>
    </div>
  );
}

function MainPackageBenefits({ quoteInfo, plans, currentPlan }: any) {
  const name = PLANS[currentPlan];
  const plan = plans && plans.find((p: any) => name === p.name);

  return (
    <div className="mt-2">
      <h2 className="font-serif mb-1">
        {plan?.whats_included_text
          ? `Benefits – ${plan.whats_included_text}`
          : `Benefits Include`}
      </h2>

      {
        <PackageBenefits
          benefits={plan ? plan.features : [{}, {}, {}, {}, {}, {}]}
          isLoading={!plan}
        />
      }
    </div>
  );
}

// ===========================================================
// Quote Options
// ===========================================================

export default function QuoteOptions() {
  const { quote, quoteInfo } = useOutletContext() as any;
  const { trackEvent } = useFacebookPixel();

  const { data: plans, isLoading: plansIsLoading } = useGET("/pre-need/plans");

  useEffect(() => {
    trackEvent({
      event: "CompleteRegistration",
      eventId: quoteInfo.event_id as string,
      data: {
        content_name: "pre-need-lead",
        content_category: "pre-need",
        state: quoteInfo.state,
      },
    });
  }, [quoteInfo.event_id]);

  return (
    <OnboardingContainer>
      <OnboardingHeader className="sm:mt-8 sm:text-3xl">
        Hi {quoteInfo.first_name}, here's{" "}
        <span className="inline-block">your quote</span>
      </OnboardingHeader>

      <div className="text-center mt-2 sm:text-xl">
        For {STATES[quoteInfo.state]}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-2">
        <OnboardingContent className="w-full grid grid-cols-1 gap-4 sm:gap-6 auto-rows-min sm:col-span-2">
          <MainPackageQuote
            plans={plans?.plans}
            quotes={quote?.quotes}
            quoteInfo={quoteInfo}
          />
        </OnboardingContent>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-2 mx-auto px-3 sm:px-4 mt-6 md:mt-4 lg:mt-0">
          <OnboardingContent className="w-full sm:p-0 p-0">
            <QuoteInformation quoteInfo={quoteInfo} />

            <QuestionsCallUs />
          </OnboardingContent>

          <OnboardingContent className="w-full sm:p-0 p-0">
            <div className="border border-2 border-oat rounded-xl overflow-hidden">
              <div className="px-4 sm:px-6">
                <div className="border-b border-b-soil mb-4 mt-5 sm:mt-6">
                  <h2 className="font-serif mb-3">Hear From Our Families</h2>
                </div>
              </div>

              <div className="px-4 sm:px-6 pb-4 sm:pb-6">
                <Reviews max={3} />
              </div>
            </div>
          </OnboardingContent>
        </div>
      </div>
    </OnboardingContainer>
  );
}
