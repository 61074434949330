import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useEffect } from "react";

import { sentryCreateBrowserRouter } from "sentry";

import Loading from "components/Loading";
import ABSplit from "components/ABSplit";
import LandingLayout from "layouts/LandingLayout";
import OnboardingLayout from "layouts/OnboardingLayout";

import ErrorPage from "pages/ErrorPage";
import QuoteOptions from "pages/QuoteOptions";
import QuoteOptionsOld from "pages/QuoteOptionsOld";
import Continue from "pages/Continue";
import Form from "pages/Form";

import PolicyOnboarding from "pages/PolicyOnboarding";

const Root = () => {
  return <Outlet />;
};

function RedirectToAfter() {
  useEffect(() => {
    window.location.href = "https://www.after.com?utm_source=after_app";
  }, []);

  return <div></div>;
}

export default sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <LandingLayout>
        <ErrorPage />
      </LandingLayout>
    ),
    children: [
      {
        index: true,
        element: <RedirectToAfter />,
      },
      {
        path: "forms/:formId/:responseId",
        element: <Form />,
      },
      {
        path: "continue",
        element: (
          <OnboardingLayout>
            <Outlet />
          </OnboardingLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/" />,
          },
          {
            path: "pre-planning",
            element: <Continue />,
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
              {
                path: "quote",
                element: (
                  <ABSplit
                    queryParam="b"
                    matchValue="b9ee09"
                    ComponentA={QuoteOptionsOld}
                    ComponentB={QuoteOptions}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "pre-planning",
        element: (
          <OnboardingLayout>
            <Outlet />
          </OnboardingLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/" />,
          },
          {
            path: "policies",
            element: (
              <OnboardingLayout>
                <Outlet />
              </OnboardingLayout>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
              {
                path: "onboarding",
                element: <PolicyOnboarding />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
