import { useParams } from "react-router-dom";

import Logo from "svg/logo-with-type.svg";
import {
  OnboardingContainer,
  OnboardingContent,
  OnboardingPanel,
  OnboardingPanelUnder,
  OnboardingHeader,
} from "layouts/OnboardingLayout";
import FormLoader from "components/FormLoader";

export default function Form() {
  const { formId, responseId } = useParams<{
    formId: string;
    responseId: string;
  }>();

  const handleSubmit = (formData: Record<string, any>) => {
    console.log("Form submitted with data:", formData);
  };

  return (
    <OnboardingContainer>
      <OnboardingHeader className="flex justify-center sm:mt-8 sm:text-3xl">
        <Logo className="w-28" />
      </OnboardingHeader>

      <div className="">
        <OnboardingContent className="w-full">
          <div className="max-w-xl mx-auto my-16">
            {!formId && (
              <div className="text-center">
                <h1 className="font-serif mb-2">
                  Sorry, we could not find that form
                </h1>
                <p>
                  Please reach out to us at{" "}
                  <a
                    href="mailto:support@after.com"
                    target="_blank"
                    className="underline underline-offset-4 decoration-dotted hover:decoration-solid"
                  >
                    support@after.com
                  </a>{" "}
                  if you need help.
                </p>
              </div>
            )}
            {formId && (
              <FormLoader
                formId={formId}
                onSubmit={handleSubmit}
                responseId={responseId || "test"}
              />
            )}
          </div>
        </OnboardingContent>
      </div>
    </OnboardingContainer>
  );
}
