import React, { createContext, ReactNode, useContext } from "react";
import { useLocation } from "react-router-dom";

import { FormConfig } from "./types";
import { substituteVariables } from "./variables";

// -----------------------
// Type Definitions
// -----------------------

/**
 * Defines the shape of the FormConfig context.
 */
interface FormConfigContextProps {
  config: FormConfig;
  variables: Record<string, unknown>;
}

/**
 * Creates a React context for FormConfig.
 * The default value is undefined to enforce the usage of a Provider.
 */
const FormConfigContext = createContext<FormConfigContextProps | undefined>(
  undefined
);

// -----------------------
// Provider Component
// -----------------------

interface FormConfigProviderProps {
  config: FormConfig;
  variables: Record<string, unknown>;
  children: ReactNode;
}

/**
 * FormConfigProvider component that supplies the FormConfig to its children.
 *
 * @param config - The form configuration to provide.
 * @param children - The child components that will consume the form configuration.
 */
export const FormConfigProvider: React.FC<FormConfigProviderProps> = ({
  config,
  variables,
  children,
}) => {
  const context = { config, variables };

  return (
    <FormConfigContext.Provider value={context}>
      {children}
    </FormConfigContext.Provider>
  );
};

/**
 * Custom hook to consume the FormConfig context.
 *
 * @returns The current FormConfig.
 * @throws Error if used outside of a FormConfigProvider.
 */
export const useFormConfig = (): FormConfigContextProps => {
  const context = useContext(FormConfigContext);
  if (!context) {
    throw new Error("useFormConfig must be used within a FormConfigProvider");
  }
  return context;
};

export const useFormTextSubstitution = () => {
  const { variables } = useFormConfig();

  function sub(input: string): string {
    return substituteVariables(input, variables);
  }

  return { substituteVariables: sub };
};

export function useFormContext(): Record<string, unknown> {
  const { state } = useLocation();

  if (state) {
    return state.form_context;
  }

  return {};
}
