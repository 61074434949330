import { FieldConfig } from "components/Form/types";

export const formFields: FieldConfig[] = [
  {
    id: "FIGbDKZb9jKo",
    title: "Are you pre-planning for yourself or a loved one?",
    ref: "01FRNM864A566J7VSYKT7R6MEG",
    properties: {
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "kOz2y1fAoF9d",
          ref: "01FRNM864AYN3FARA7BZ4VH0RP",
          label: "Myself",
        },
        {
          id: "a04JtIJfMWn3",
          ref: "01FRNM864AYMDAFC23DZ6NGHGR",
          label: "Loved one",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "0Ml88z8Cj6lE",
    title: "Let's collect {{var:subject}} information",
    ref: "32ff170e-2e1f-4bb0-b883-fae4a180b66a",
    properties: {
      button_text: "Continue",
      show_button: true,
      fields: [
        {
          id: "lDelX1phIEbn",
          title: "What is {{var:subject}} first name?",
          ref: "e9c8b42f-2571-481f-8b34-c729a5963e6a",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "EotpBwP9qh8j",
          title: "What is {{var:subject}} middle name?",
          ref: "74ca0b1c-a1a0-415a-9106-52f2e50b213e",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "MAOiBmrVrSUE",
          title: "What is {{var:subject}} last name?",
          ref: "fab37719-2984-4f80-8c62-a7c6867a500c",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "9BtNaT1WtV7V",
          title: "What is {{var:subject}} suffix?",
          ref: "5047e772-f9bf-480e-9a61-34ef667f08f5",
          properties: {
            randomize: false,
            alphabetical_order: false,
            options: [
              {
                id: "XsKOl14qD9ke",
                ref: "3ffaa9de-2116-4b12-9a72-3add3d41b021",
                label: "Jr.",
              },
              {
                id: "6OefecZ9pPVj",
                ref: "929a8e64-94b7-4efe-ac03-c8b79d36cd19",
                label: "Sr.",
              },
              {
                id: "nsYZatWn4KoI",
                ref: "bf3f629d-8cc0-4c54-9fb2-8e36cc85fbd5",
                label: "I",
              },
              {
                id: "x02TCyjfaDFm",
                ref: "e635a61b-8948-4c6c-8ca9-8b8fb552bbe0",
                label: "II",
              },
              {
                id: "xBumJwpXH9Wg",
                ref: "bae2ae53-dd85-47f7-b4b7-99e886490e34",
                label: "III",
              },
              {
                id: "G8BjNDjbRmB3",
                ref: "c0bcc651-64c4-4032-b67f-1fda7a2cb58b",
                label: "IV",
              },
              {
                id: "wSh5RJysc7yY",
                ref: "1f7b917e-8caa-40ba-b7b1-b22a4fb32abf",
                label: "V",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "single_select",
        },
        {
          id: "TI8Vx9pEwq6z",
          title: "What is {{var:subject}} SSN?",
          ref: "9b194ed7-647d-4dfe-b148-3db004d409d9",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "vXArKQZt67K4",
          title: "What is {{var:subject}} gender?",
          ref: "adc78010-cf0a-41cf-b123-772c3cda7022",
          properties: {
            randomize: false,
            allow_multiple_selection: false,
            allow_other_choice: false,
            vertical_alignment: true,
            options: [
              {
                id: "7FuGuCaO4mok",
                ref: "378a5f98-52ff-445c-b4b2-8615e9f23d5a",
                label: "Male",
              },
              {
                id: "ILUvBbZib43n",
                ref: "bb728d02-301b-49f5-a000-3bc02e1e9e5c",
                label: "Female",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "multiple_select",
        },
        {
          id: "C0yeGHdbrFM9",
          title: "What is {{var:subject}} date of birth?",
          ref: "772e61d5-1e9b-427b-81be-13e329504789",
          properties: {
            separator: "/",
            structure: "MMDDYYYY",
          },
          validations: {
            required: false,
          },
          type: "date",
        },
        {
          id: "e7xccefvj5kV",
          title: "What is {{var:subject}} phone number?",
          ref: "e1a18c45-592f-46de-8d08-32d738191986",
          properties: {
            default_country_code: "us",
          },
          validations: {
            required: false,
          },
          type: "phone_number",
        },
        {
          id: "ZeCJWkpsjHip",
          title: "What is {{var:subject}} email address?",
          ref: "029f4032-51c6-44e5-8443-17512098597b",
          properties: {},
          validations: {
            required: false,
          },
          type: "email",
        },
        {
          id: "PrumvDlmdXFZ",
          title: "What is {{var:subject}} Primary Care Physician's full name?",
          ref: "061e27a8-a304-4968-a9c1-68bf0739d4cd",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "nxVG1BLUr7JI",
          title:
            "What is {{var:subject}} Primary Care Physician's phone number?",
          ref: "16b5afd4-3e18-4772-841f-742b4e50c69b",
          properties: {
            default_country_code: "US",
          },
          validations: {
            required: false,
          },
          type: "phone_number",
        },
        {
          id: "z32LpWZNvHK8",
          title:
            "What is {{var:subject}} Primary Care Physician's Street Address?",
          ref: "6831879a-8c8d-40b3-b5c9-beb65c159df5",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "2WU86VrOtzU1",
          title: "What is {{var:subject}} Primary Care Physician's City?",
          ref: "8da83a04-46df-477a-9f42-cd70da607a80",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "BTLreCFBd8sC",
          title: "What is {{var:subject}} Primary Care Physician's State?",
          ref: "c6e43d99-27c0-4f23-997e-596cb580bebc",
          properties: {
            randomize: false,
            alphabetical_order: false,
            options: [
              {
                id: "MbBdRrlfRDuj",
                ref: "1a0109ff-09d9-4698-bd27-324ca1fbd46c",
                label: "Alabama",
              },
              {
                id: "exSm8jsY5nfA",
                ref: "a3bd2857-a8a4-4e5d-8e30-45a2d559a7f2",
                label: "Alaska",
              },
              {
                id: "52oJC9JNDFO2",
                ref: "af97ef28-423f-44e0-b711-dc11adcf59d2",
                label: "American Samoa",
              },
              {
                id: "swG1ZIESgZlo",
                ref: "8c1f1adf-c1e4-4719-a058-d857bdeb5103",
                label: "Arizona",
              },
              {
                id: "5BtXuhxjLgQU",
                ref: "1f6c18b2-ccf6-4801-9bab-8ea6a5d0dd0b",
                label: "Arkansas",
              },
              {
                id: "zP9BwQYanu8S",
                ref: "228881b7-72e3-445a-b180-cf4a7e834524",
                label: "California",
              },
              {
                id: "VvWuiMIxNfua",
                ref: "a7b118b5-883f-4c1b-b0dc-4a8ac0e1c8dd",
                label: "Colorado",
              },
              {
                id: "HyTktUFJ56BD",
                ref: "6c57916d-03c3-43e2-a725-a5be741a5eba",
                label: "Connecticut",
              },
              {
                id: "zLYLhefSvwFR",
                ref: "d666dbc2-200f-4d45-8551-3212962a718e",
                label: "Delaware",
              },
              {
                id: "0v3FcXUa5wam",
                ref: "22f9e84a-8ece-4d89-a858-4feaca967b1e",
                label: "District of Columbia",
              },
              {
                id: "8QvN4lgA5Yg2",
                ref: "359c0559-966e-4b34-a889-221f2f7cffa4",
                label: "Florida",
              },
              {
                id: "5pI2WyOEdTS2",
                ref: "66ac61f4-3223-455a-ad34-bfb39aa19f73",
                label: "Georgia",
              },
              {
                id: "rfX7zcWq8JiF",
                ref: "31c3b2cc-314d-4ea2-aa57-d36bccd31ea9",
                label: "Guam",
              },
              {
                id: "9cCh6O7BGk1m",
                ref: "d5c4751d-d6f2-445c-a60f-ab697b9cdbf2",
                label: "Hawaii",
              },
              {
                id: "DphbS3vfyYtR",
                ref: "24cafd1e-9c9e-4082-8604-13ac3d1efcc7",
                label: "Idaho",
              },
              {
                id: "Bcercg1dj6p8",
                ref: "eac33932-c3f3-43c3-9a37-bbcf5a6ec8a4",
                label: "Illinois",
              },
              {
                id: "bQS1CnS7eBUp",
                ref: "6e84b38c-b747-40fc-b7f9-c0276ec90854",
                label: "Indiana",
              },
              {
                id: "2LahGK2PVq6Z",
                ref: "cf5e68bc-c10f-457f-9f0f-5efd993abfe1",
                label: "Iowa",
              },
              {
                id: "KUDmqwRlvuRG",
                ref: "5bb82939-7de2-423c-b248-8e705facf5b4",
                label: "Kansas",
              },
              {
                id: "ZH9ZtwrhoaYC",
                ref: "0ce9264b-3ff3-4a8d-886c-7743bbb40327",
                label: "Kentucky",
              },
              {
                id: "2peRzr28RowR",
                ref: "b664a5f1-8429-4f73-a3d0-b7189f74e714",
                label: "Louisiana",
              },
              {
                id: "Xzpe4VHTMvo5",
                ref: "56146725-856a-44b4-b7c6-52b4568e7143",
                label: "Maine",
              },
              {
                id: "FqfNPOIGUI6s",
                ref: "8bbfdc43-8374-45bb-b525-9ded277305e8",
                label: "Maryland",
              },
              {
                id: "1EnsbhcHEnl0",
                ref: "879ab4cd-1178-4979-997b-0967a307bda9",
                label: "Massachusetts",
              },
              {
                id: "naPr9UDDmXIn",
                ref: "0f4b4933-3d5f-4eab-91b0-6074f9a4a504",
                label: "Michigan",
              },
              {
                id: "YfZw7A2S8jGB",
                ref: "90bfe6c5-f78f-4542-8292-66dc30aa9847",
                label: "Minnesota",
              },
              {
                id: "DvNFbkm43ltr",
                ref: "79a028f6-757c-4f1f-9638-3b211b195d70",
                label: "Mississippi",
              },
              {
                id: "pqVXqCJ4mlHL",
                ref: "da7e5c7d-829f-4451-876d-978109277791",
                label: "Missouri",
              },
              {
                id: "uLqYXk8AH6Jq",
                ref: "af908d66-0142-462d-aeed-537d5186a3b5",
                label: "Montana",
              },
              {
                id: "mQ7g5zl7VBLY",
                ref: "db2b71d6-6455-4e19-836d-c6a5ca1c888d",
                label: "Nebraska",
              },
              {
                id: "ARQzkhyigP44",
                ref: "bf800a5c-a713-424d-8260-bc496bfa4640",
                label: "Nevada",
              },
              {
                id: "VtMiPGsuYNvi",
                ref: "23823c09-3b98-47dd-8839-3d19b488089f",
                label: "New Hampshire",
              },
              {
                id: "bB1Mp9S8N348",
                ref: "b3dc35bd-b883-4c02-8bd8-f4c57e375d07",
                label: "New Jersey",
              },
              {
                id: "WwZcRK7pBu6U",
                ref: "24b19623-66f4-4288-954e-c101a0d9c5b7",
                label: "New Mexico",
              },
              {
                id: "cRhi2lywl1lV",
                ref: "57b180af-4a19-42e5-916f-5c8bd5350d66",
                label: "New York",
              },
              {
                id: "t2xxdXxavQ4B",
                ref: "7fb46477-0f85-4f4d-840a-3e01586b6d94",
                label: "North Carolina",
              },
              {
                id: "e47hUWs3LZD9",
                ref: "86c82128-4d4b-4e90-9f59-d6b392b642dc",
                label: "North Dakota",
              },
              {
                id: "Wx8FG2uho6d5",
                ref: "16e30560-d13f-4f47-b366-3e227ab37a00",
                label: "Northern Mariana Islands",
              },
              {
                id: "c5cgLB0Tw6TF",
                ref: "052e76b8-2a74-4812-b09f-18ef7ee1b57f",
                label: "Ohio",
              },
              {
                id: "X2zLV1Uf610z",
                ref: "b3af3f64-edc5-49f9-9079-c2d36856f6b9",
                label: "Oklahoma",
              },
              {
                id: "S1HyiCdOe16H",
                ref: "b86b905e-50b3-48d6-8a73-680e6ea07acc",
                label: "Oregon",
              },
              {
                id: "7ZA874VGXWdh",
                ref: "170b4755-1004-4b8a-94f0-849e6c673810",
                label: "Pennsylvania",
              },
              {
                id: "SSofohDWZzj9",
                ref: "277797a3-067f-4619-853a-81afd162ff34",
                label: "Puerto Rico",
              },
              {
                id: "mBFQvfVYHXRl",
                ref: "789c87e6-95e1-4618-af7a-d5f0845ef8a0",
                label: "Rhode Island",
              },
              {
                id: "y5ZZGsGsgvfS",
                ref: "f5d72d69-8f55-438f-81b3-4d45915bca8a",
                label: "South Carolina",
              },
              {
                id: "lss1KcQXJPT2",
                ref: "976eb4be-6fdd-4d04-8253-0ed1d7bb7cfc",
                label: "South Dakota",
              },
              {
                id: "F8y786WWHeq6",
                ref: "ed475f6b-2dc4-4a7e-a73d-370d524904bc",
                label: "Tennessee",
              },
              {
                id: "tM0lTY5kYGqV",
                ref: "f57401e7-2d6f-4125-bd21-85b208bb198e",
                label: "Texas",
              },
              {
                id: "TOvCE7uTmkWi",
                ref: "f93b2a46-8f9c-4502-afe0-a21846c43259",
                label: "Utah",
              },
              {
                id: "Vwb6SMHP0aaf",
                ref: "a1e5e985-d531-4a34-9d88-646263a706c3",
                label: "Vermont",
              },
              {
                id: "jCOUyjPCU34Q",
                ref: "66dc2ae8-ffd4-4e86-9d90-9a0a48476969",
                label: "Virgin Islands",
              },
              {
                id: "nZR4lA4NyImf",
                ref: "5e2ae16a-c37f-425a-8d33-b210af258c4d",
                label: "Virginia",
              },
              {
                id: "vquLSjKukePQ",
                ref: "e4f56e47-e380-4b92-a590-b4a15d5bd44a",
                label: "Washington",
              },
              {
                id: "FhKeQBSrtnia",
                ref: "a7ae5aa0-4268-4e0b-b670-9909659452a4",
                label: "West Virginia",
              },
              {
                id: "ZFUnU9c7XhO3",
                ref: "e72d9448-47c3-45c8-ab8d-ba0a85c61545",
                label: "Wisconsin",
              },
              {
                id: "jK8iWNqzORSY",
                ref: "93b17e6f-d87d-4dbf-a9a8-59ac58e54ca9",
                label: "Other",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "single_select",
        },
        {
          id: "SDbB3rpbCIJn",
          title: "What is {{var:subject}} Primary Care Physician's Zip Code?",
          ref: "9162a5c3-b0a0-4a6a-a1b5-a21b9fe497e2",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "1h1yyw0OfKan",
          title: "What is {{var:subject}} mailing address street?",
          ref: "5f2db33c-e0c2-4b27-a458-da022988a88f",
          properties: {},
          validations: {
            required: false,
          },
          type: "long_text",
        },
        {
          id: "BkgO9ybRwRBY",
          title: "What is {{var:subject}} mailing address city?",
          ref: "505f0fd2-676f-4589-ae14-164572ab587c",
          properties: {},
          validations: {
            required: false,
          },
          type: "long_text",
        },
        {
          id: "cqdXpDdFwRKK",
          title: "What is {{var:subject}} mailing address state?",
          ref: "43319e81-209f-47eb-89e7-d93c14721856",
          properties: {
            randomize: false,
            alphabetical_order: false,
            options: [
              {
                id: "DpBpyRFkFo8O",
                ref: "86ccae37-ef8e-4ee2-a66a-20f4b5630ffe",
                label: "Alabama",
              },
              {
                id: "kak7qmIAr1vL",
                ref: "3c6a4441-0744-4a78-af98-427972a3245b",
                label: "Alaska",
              },
              {
                id: "igBmG4LpGHPD",
                ref: "94f44412-5e70-425b-b271-be1d59acd361",
                label: "American Samoa",
              },
              {
                id: "BN1jGuaAV4LU",
                ref: "90686ceb-3cca-4788-8041-65c3f4e1651d",
                label: "Arizona",
              },
              {
                id: "f7QfUSItsY8t",
                ref: "c4a8e374-a446-443e-8d9e-25a0a5a0a441",
                label: "Arkansas",
              },
              {
                id: "1LXmgOb17NfJ",
                ref: "c9490f53-c14f-4d92-96b8-1e968fe4b910",
                label: "California",
              },
              {
                id: "nWa5KCUZGBt3",
                ref: "55176a6e-6343-4f30-add2-5d042f3ea97b",
                label: "Colorado",
              },
              {
                id: "Hf9lwA8zVBog",
                ref: "d9f239b6-20ee-44f2-8903-abddf1da3e82",
                label: "Connecticut",
              },
              {
                id: "IuqJYswOQiAS",
                ref: "9af8f103-7299-47d1-b0e9-a45d719660bd",
                label: "Delaware",
              },
              {
                id: "9WFKBjRa4QEL",
                ref: "0fabbd76-18b1-4db5-ac95-64722ee4ee21",
                label: "District of Columbia",
              },
              {
                id: "Ebcyg2kHCPpO",
                ref: "cbb47725-806d-433e-8d24-0d9f1c504e4a",
                label: "Florida",
              },
              {
                id: "ImncySlwl30w",
                ref: "274fe663-ed07-4bad-a763-ee2f939a78a7",
                label: "Georgia",
              },
              {
                id: "0ULwrOnSbZ9d",
                ref: "ff455ad8-feac-4f66-9236-c2e4d1e87734",
                label: "Guam",
              },
              {
                id: "vnvo9D5HXCtG",
                ref: "5eb96a35-9052-4880-b2fe-c667c44e48a3",
                label: "Hawaii",
              },
              {
                id: "E8463Uv3kVeL",
                ref: "bc4fd991-6c03-41b9-970c-6f6b48bf85fa",
                label: "Idaho",
              },
              {
                id: "oEaY3TgfXKRi",
                ref: "c58ec653-a9e9-4474-9305-0b3201c197d3",
                label: "Illinois",
              },
              {
                id: "gUZL1xS4gCMM",
                ref: "434576c7-c393-4a20-a578-232031c6561d",
                label: "Indiana",
              },
              {
                id: "Q7ysCh809R7n",
                ref: "dbf7f60b-c135-4a4c-92d3-b1132767e3bc",
                label: "Iowa",
              },
              {
                id: "hPMX3bfneqUn",
                ref: "72a3a350-8f95-4cfe-ba17-5dba99a7a7b3",
                label: "Kansas",
              },
              {
                id: "y3pMoE3DNSGF",
                ref: "c9b7d9a3-8034-459e-a9d7-324cd0bbd4f2",
                label: "Kentucky",
              },
              {
                id: "fOmeUi1eqOoQ",
                ref: "1f221cd6-07a5-45c1-bcd1-99f29502ae72",
                label: "Louisiana",
              },
              {
                id: "ZeWje0En9xzY",
                ref: "25c9b7f9-e88c-4f8c-a26f-7aee5249e0a7",
                label: "Maine",
              },
              {
                id: "YQxGgU0Dc8HN",
                ref: "e9adc7e7-995c-4433-887c-89648509a911",
                label: "Maryland",
              },
              {
                id: "XsYGY0YO2utA",
                ref: "49755ab3-e6a3-4f41-aa23-4ac186b84b1d",
                label: "Massachusetts",
              },
              {
                id: "1uq1e87bTM4d",
                ref: "50271544-8fcb-4949-b8a5-549029e4cce2",
                label: "Michigan",
              },
              {
                id: "qsa7Tnezc5qZ",
                ref: "843b1ed8-a903-45a5-a8b6-73e8d1c6a18a",
                label: "Minnesota",
              },
              {
                id: "90dsiRMeToZN",
                ref: "62698e01-2947-487f-a3ed-7c1aa0c0cb36",
                label: "Mississippi",
              },
              {
                id: "FEIqlh80qsLE",
                ref: "5e86d73d-f846-41c9-aee0-e7eca08c341b",
                label: "Missouri",
              },
              {
                id: "ZxGBdfL1ZIoh",
                ref: "b5e16510-b9e3-4eb1-ba82-18ba3945b499",
                label: "Montana",
              },
              {
                id: "5uYUlNw6QzgT",
                ref: "73665466-129d-4e7e-92a5-1a80ffc18388",
                label: "Nebraska",
              },
              {
                id: "zCroizwArPSL",
                ref: "0a88e24d-457d-47d1-95ee-88b94b1f3db1",
                label: "Nevada",
              },
              {
                id: "DtmNFswaLGOe",
                ref: "a3efcb21-9b6c-4ffa-ad00-00b0b51b696d",
                label: "New Hampshire",
              },
              {
                id: "fZQLr6mB3Doo",
                ref: "3dfcb773-dff2-44db-a924-be4fb30b2072",
                label: "New Jersey",
              },
              {
                id: "5Sn46C0thpUN",
                ref: "30cfa95e-7fe7-4f87-9aa8-3c1a78e10a46",
                label: "New Mexico",
              },
              {
                id: "vSPP7Jt2Q3Yp",
                ref: "d7c67265-fcf2-48e6-bcb6-3b11d981b4b6",
                label: "New York",
              },
              {
                id: "7IVXTvThWoPT",
                ref: "11bac51a-8836-4ce3-8e56-15f75a4e20a5",
                label: "North Carolina",
              },
              {
                id: "Pu4J98I7vemw",
                ref: "98165922-26fb-4b1c-a7d1-355e52d81126",
                label: "North Dakota",
              },
              {
                id: "h0H4tQ8gVBDt",
                ref: "f8247792-0993-4d3f-a7a9-c0b58aaf078a",
                label: "Northern Mariana Islands",
              },
              {
                id: "LMJmqyLlDGBB",
                ref: "bde770fd-2556-4d40-801b-1e3d6fff5a0b",
                label: "Ohio",
              },
              {
                id: "oBJaAJhXe1Ab",
                ref: "9fa1f693-9891-4b81-b06b-e4804c3f3f52",
                label: "Oklahoma",
              },
              {
                id: "b4HgEvaa1eWs",
                ref: "751e8e4f-d960-4775-8a71-c1ce34d2b4b3",
                label: "Oregon",
              },
              {
                id: "u30fGe8FOrgk",
                ref: "0ed93578-cfde-4d93-9e78-e5fafb255d8a",
                label: "Pennsylvania",
              },
              {
                id: "uBjY7WD4XquX",
                ref: "cd91cc13-41a2-4690-b2a1-cb823356db84",
                label: "Puerto Rico",
              },
              {
                id: "xCFwS6OC6syc",
                ref: "ee2e4d10-d071-4064-b33c-e06baf66264d",
                label: "Rhode Island",
              },
              {
                id: "JSbd0Lv4gyVv",
                ref: "8fd17c67-0866-4ebd-a0bd-c42b2bba9847",
                label: "South Carolina",
              },
              {
                id: "hyoXhjvtKfSW",
                ref: "9ae6e3d2-44e7-452d-9bd3-2b35f6691fa3",
                label: "South Dakota",
              },
              {
                id: "Q1DEdOq5rq0i",
                ref: "b504e22e-3071-4f8f-92c2-9d694f356915",
                label: "Tennessee",
              },
              {
                id: "amfZ5rEx8mhY",
                ref: "16c75c4c-09da-4e47-ba68-0a0a835c9b38",
                label: "Texas",
              },
              {
                id: "XKFHM219MN1k",
                ref: "59978caa-ae4b-4408-8c19-062129d4d003",
                label: "Utah",
              },
              {
                id: "ROGLFYC7ZHje",
                ref: "7a532305-3ae5-455c-8eae-ff8ad7379924",
                label: "Vermont",
              },
              {
                id: "ZgmYoBvE0aLj",
                ref: "20d4fca9-fb61-4afa-8543-502358a1ee5a",
                label: "Virgin Islands",
              },
              {
                id: "QOd5OPK2TqB7",
                ref: "8963eba8-9621-4fa7-b647-44c360a64572",
                label: "Virginia",
              },
              {
                id: "8ZsVNyPG2LH0",
                ref: "499583f9-ee05-480d-a162-4a285239e878",
                label: "Washington",
              },
              {
                id: "lFVsa6UoWHOI",
                ref: "cc596129-2dbf-45b1-805a-c2e5f3362f65",
                label: "West Virginia",
              },
              {
                id: "dJsTotDk2PM6",
                ref: "c4875540-4737-4105-a047-05d8b36d6f39",
                label: "Wisconsin",
              },
              {
                id: "fvqfoiJWleaW",
                ref: "7f5ea935-f097-4386-adfa-7aee9a0cdfdd",
                label: "Other",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "single_select",
        },
        {
          id: "0v2P28lhhMQB",
          title: "What is {{var:subject}} mailing address zip code?",
          ref: "88fe8c05-f310-4743-af8d-d75ab3ed3194",
          properties: {},
          validations: {
            required: false,
          },
          type: "long_text",
        },
      ],
    },
    type: "group",
  },
  {
    id: "4A5R5lNx0tiI",
    title: "Now let's collect your information",
    ref: "53ddefca-332c-4e4a-86ff-1d3c387e08c5",
    properties: {
      button_text: "Continue",
      show_button: true,
      fields: [
        {
          id: "6rsGMJcJHXcd",
          title: "Your first name",
          ref: "4d078dd2-c0ae-4e70-90f7-dc7c602c70e4",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "TCQVzMpWbPN0",
          title: "Your middle name",
          ref: "d6b3aa27-543e-442e-9709-aa8c9eb461a8",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "Sum0WL8Q8PC7",
          title: "Your last name",
          ref: "ab499856-91ae-4f66-b45f-d96adc621cca",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "ROaiK3FSCZc0",
          title: "Your suffix",
          ref: "5bb661c9-e014-4bde-bdaf-67ef2bf823fe",
          properties: {
            randomize: false,
            alphabetical_order: false,
            options: [
              {
                id: "f8bjf1RiP8DF",
                ref: "04698d0e-f2f9-4c3e-9f7f-17045df70eeb",
                label: "Jr.",
              },
              {
                id: "uatzjQVqjiKb",
                ref: "62bb96eb-d663-433e-bc74-bb400eb4fe89",
                label: "Sr.",
              },
              {
                id: "bN4uf4pm98lq",
                ref: "fdcf06cb-b7f5-4485-bfda-01caa29a8e16",
                label: "I",
              },
              {
                id: "hFJWWyzfNviy",
                ref: "4d6b5f8b-74d1-429c-831c-7eeb18a1d276",
                label: "II",
              },
              {
                id: "CCQ43AwieDOx",
                ref: "8b91c454-06e0-4980-9674-0b95f03fba3c",
                label: "III",
              },
              {
                id: "LcIPxXy4qesW",
                ref: "3ad6ee4f-567d-4a53-bda0-4c9370d6772b",
                label: "IV",
              },
              {
                id: "cpZRdAQbxqea",
                ref: "35cf9ec1-5661-4e66-a52a-f69f337d219a",
                label: "V",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "single_select",
        },
        {
          id: "2SDHFRAR7gPU",
          title: "Your SSN",
          ref: "055279d9-d78b-41a3-9340-c618f11f9b48",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
        {
          id: "S0J2H39ctyNZ",
          title: "What is your relationship to the insured?",
          ref: "9f1b7411-1bc1-463b-acf0-d305ac67e303",
          properties: {
            randomize: false,
            allow_multiple_selection: false,
            allow_other_choice: true,
            vertical_alignment: true,
            options: [
              {
                id: "kdgPtzsNirjU",
                ref: "96079c31-9d17-4498-96ff-e0a87b2526a1",
                label: "Child",
              },
              {
                id: "8x3C4CmIykKy",
                ref: "ac49b049-83ae-4ad4-aa0d-fe65d31cbb48",
                label: "Grand Child",
              },
              {
                id: "2Km9II6eWyhA",
                ref: "76263716-f323-4b13-8295-2a9ca4fa1790",
                label: "Spouse",
              },
              {
                id: "Mji3JyTfVS8F",
                ref: "c8fa0f11-e189-48ff-91a5-8fb68f956b8f",
                label: "Sibling",
              },
              {
                id: "Uwp2tVwiKNSF",
                ref: "bdebf5df-f701-4832-9213-cd485af0c8b2",
                label: "Parent",
              },
              {
                id: "v5AquXII0Ov1",
                ref: "59da7ffc-e48d-41dc-a704-27b8c8b0d787",
                label: "Grand Parent",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "multiple_select",
        },
        {
          id: "RLqNGltaSkJ6",
          title: "Your phone number",
          ref: "24fc3bf5-c88b-40f7-b713-a01e532310df",
          properties: {
            default_country_code: "US",
          },
          validations: {
            required: false,
          },
          type: "phone_number",
        },
        {
          id: "kcIDk4h5lzZE",
          title: "Your email address",
          ref: "3b654841-ec7e-4bfa-b308-7d2f31690ce8",
          properties: {},
          validations: {
            required: false,
          },
          type: "email",
        },
        {
          id: "lMPyimavPpHP",
          title: "Your mailing address street",
          ref: "2c0cd8cb-13c5-4691-926e-4a14975548c6",
          properties: {},
          validations: {
            required: false,
          },
          type: "long_text",
        },
        {
          id: "TVY1d5Uup8u8",
          title: "Your mailing address city",
          ref: "c020e6bb-b7eb-4733-903d-a0377f89b0cc",
          properties: {},
          validations: {
            required: false,
          },
          type: "long_text",
        },
        {
          id: "k0XmFxqF6ZwL",
          title: "Your mailing address state",
          ref: "6837a5b4-586d-48bb-91a6-303a1c693293",
          properties: {
            randomize: false,
            alphabetical_order: false,
            options: [
              {
                id: "k0axriMkcjMB",
                ref: "60821721-0186-414b-8299-8dcebddb9f12",
                label: "Alabama",
              },
              {
                id: "x7yI7a6lS0QR",
                ref: "a89b41f1-9914-4782-ba12-18d5c3b0f3ae",
                label: "Alaska",
              },
              {
                id: "bo8aOuNN8hrl",
                ref: "de6fbfcf-6811-48b2-b694-34baa3dea0f8",
                label: "American Samoa",
              },
              {
                id: "EWOpH6dFm21V",
                ref: "b9df8699-4432-4887-9b1f-fcf700059c38",
                label: "Arizona",
              },
              {
                id: "PkfkIvFmaRNr",
                ref: "8c852a5b-4b05-41aa-af51-9926037a02b5",
                label: "Arkansas",
              },
              {
                id: "XqsGU8qUyM8g",
                ref: "b53a8424-c200-4bc8-96ae-54fcfc2638d9",
                label: "California",
              },
              {
                id: "IEPCWkMTWqIF",
                ref: "824c6c41-5835-4548-a207-9251c939a7ad",
                label: "Colorado",
              },
              {
                id: "Mb1BIm1mUaab",
                ref: "f7c66f1e-9317-4318-ab62-b817fea7c3b6",
                label: "Connecticut",
              },
              {
                id: "wHJ4K9yeOXB3",
                ref: "de9d64c7-efef-426b-a58b-ed7f17f0fb97",
                label: "Delaware",
              },
              {
                id: "jZvFx0Dc1jgi",
                ref: "06574cfb-a831-444c-95d3-a1c6fd0fba38",
                label: "District of Columbia",
              },
              {
                id: "FmsmrDP6EhTe",
                ref: "c4df88e8-51cf-45c3-bd28-c2918248271a",
                label: "Florida",
              },
              {
                id: "TsmulCQdQBHC",
                ref: "798ba215-194e-4474-a37b-4f978291f7a8",
                label: "Georgia",
              },
              {
                id: "G7xeXsGxVk87",
                ref: "72dcb6c2-fa81-48c5-9bf0-894e8f28ff8c",
                label: "Guam",
              },
              {
                id: "ts39XwTuM5mK",
                ref: "380f76ea-1b70-4f3f-9a12-c727b44115ce",
                label: "Hawaii",
              },
              {
                id: "PacINcbgL2rb",
                ref: "cefe4472-3fb9-4fbd-8d4e-dd005cbc6b58",
                label: "Idaho",
              },
              {
                id: "0TQTDlncDuQk",
                ref: "669b652f-c2eb-4d11-ba87-06077d96163d",
                label: "Illinois",
              },
              {
                id: "ZmIjh80DBCDl",
                ref: "1b7966ff-83f7-42ed-88e4-a95488e96979",
                label: "Indiana",
              },
              {
                id: "ytcj76GVAzJW",
                ref: "8d100648-8150-4099-8146-a9e6cac0d196",
                label: "Iowa",
              },
              {
                id: "2wnJuukHHgai",
                ref: "9ee62002-c076-4f1b-85a4-405b8ebe8149",
                label: "Kansas",
              },
              {
                id: "TtqgdT2nrEMH",
                ref: "355f85a9-0104-4304-97fc-012542f85af9",
                label: "Kentucky",
              },
              {
                id: "InzA3zLpPJug",
                ref: "7debebaa-bca5-4422-ae96-d450070e61ad",
                label: "Louisiana",
              },
              {
                id: "Z6G7ULcw4FV8",
                ref: "154b2c2a-a899-48bb-b1c9-66c516ba880c",
                label: "Maine",
              },
              {
                id: "9uod2ICjY6Ml",
                ref: "f6288feb-9f59-47d7-8bc3-1868f2f6dd8f",
                label: "Maryland",
              },
              {
                id: "sFXCrWSuZADi",
                ref: "4aa6efac-9167-467c-9004-1dbe8590b8eb",
                label: "Massachusetts",
              },
              {
                id: "VWOsH0gU5R4p",
                ref: "8deda580-74f4-46cd-bd2d-1d0d6ff44228",
                label: "Michigan",
              },
              {
                id: "eoDlFqwVW8LR",
                ref: "edb15da7-c6b4-44ad-bc73-c0a6364f0032",
                label: "Minnesota",
              },
              {
                id: "zLD3ZfSwwJne",
                ref: "b63b411f-0f50-4f41-90c2-9e554c11f241",
                label: "Mississippi",
              },
              {
                id: "WYKzo0Ip0iDD",
                ref: "b288cc1b-0075-48e5-a248-d94a03a3aba6",
                label: "Missouri",
              },
              {
                id: "dvI8joe76FC8",
                ref: "be11f0cd-5a9b-4e58-95fa-b0063d7c0dec",
                label: "Montana",
              },
              {
                id: "AHKsSNWGCv9s",
                ref: "f5bed843-9b58-4bc0-b71a-3fd2702f6ccb",
                label: "Nebraska",
              },
              {
                id: "s4gkvqpbp9Dw",
                ref: "b1559fd2-3258-4059-8984-d541cddbfed4",
                label: "Nevada",
              },
              {
                id: "NYigQPEbdAJm",
                ref: "7a0f9e36-3521-465f-9f2f-33c010b3a090",
                label: "New Hampshire",
              },
              {
                id: "e0HgU6zQK4Zn",
                ref: "cb92d604-4ba3-4325-89c5-00d36dd3add6",
                label: "New Jersey",
              },
              {
                id: "87kLriSwiAWq",
                ref: "056f31ee-1e32-458f-b814-a47e64e89fc2",
                label: "New Mexico",
              },
              {
                id: "sjclRroxYOPA",
                ref: "67ee2fdf-fb04-400d-9777-d6fea4cbd172",
                label: "New York",
              },
              {
                id: "Z4GtuUC4wZxD",
                ref: "396ae832-2fec-49c9-9de1-cf5a56a1833a",
                label: "North Carolina",
              },
              {
                id: "g7bLMwVjgGVF",
                ref: "e1b408c4-18a7-4afe-9165-45ebef205c7a",
                label: "North Dakota",
              },
              {
                id: "Cez1IeaClL2M",
                ref: "aff93f61-24c3-4464-b37d-900261452929",
                label: "Northern Mariana Islands",
              },
              {
                id: "CMvdWNGeVsuS",
                ref: "73003a49-ce9c-4bc7-9edc-d31e81cafa03",
                label: "Ohio",
              },
              {
                id: "fSA5HlqC9nFj",
                ref: "41cda5bd-f34e-43cb-8e68-a6737f28ccfa",
                label: "Oklahoma",
              },
              {
                id: "9QiqCqRz9uaO",
                ref: "08b19d63-913f-40d2-99e6-67044a784f39",
                label: "Oregon",
              },
              {
                id: "c6pUUNy2GQ7i",
                ref: "7d3155b4-c511-41f2-934c-f1d553c0c7e5",
                label: "Pennsylvania",
              },
              {
                id: "U8bHC942i8n6",
                ref: "51ad1104-3ae3-4104-93c1-66959058bcbc",
                label: "Puerto Rico",
              },
              {
                id: "cQdKszmiYU3n",
                ref: "abaf3987-26cb-40d6-9208-1cb293effaa2",
                label: "Rhode Island",
              },
              {
                id: "EgJbchBsd2GT",
                ref: "39b2b669-6301-49b9-9705-c0bf558a2dd4",
                label: "South Carolina",
              },
              {
                id: "HzHbQL9vTckt",
                ref: "975b32f1-146c-4c1a-b24e-a1ce41cb2dee",
                label: "South Dakota",
              },
              {
                id: "CS342Sv68O0S",
                ref: "2f909709-2847-471d-bde7-d144b0707958",
                label: "Tennessee",
              },
              {
                id: "OY8QvZDhn63D",
                ref: "53b41500-3ef8-443b-adb3-44a2854b760b",
                label: "Texas",
              },
              {
                id: "tgGV96HhqFTT",
                ref: "aaf10749-5d6f-4ca7-a74c-4712e2bf4e62",
                label: "Utah",
              },
              {
                id: "M18MTDS0cV0K",
                ref: "42bd22b3-7ec4-4c5e-abda-594bb367d17f",
                label: "Vermont",
              },
              {
                id: "fMFlX2BQz9hk",
                ref: "9475b3f7-2056-4627-a4ba-bc61d0cf5954",
                label: "Virgin Islands",
              },
              {
                id: "0ig3E7A0ahcZ",
                ref: "1c237251-9c25-4a0c-bd6f-cc751dc1a9dd",
                label: "Virginia",
              },
              {
                id: "w4vzkaTWtF2k",
                ref: "c9c35192-4b8b-4d8e-8832-abbe5addf920",
                label: "Washington",
              },
              {
                id: "bR0zBT63d8sx",
                ref: "451995fc-aeeb-4c40-badd-db7f285992db",
                label: "West Virginia",
              },
              {
                id: "w5Dhpp8qGLlP",
                ref: "b6fe277d-a31d-4fc6-abc2-ea90f908dece",
                label: "Wisconsin",
              },
              {
                id: "T7Wer1URz50E",
                ref: "275a8e36-2bf1-4394-b817-c2ecb915564d",
                label: "Other",
              },
            ],
          },
          validations: {
            required: false,
          },
          type: "single_select",
        },
        {
          id: "FYJBdu6pqJhR",
          title: "Your mailing address zip",
          ref: "47abff78-4b13-42e4-96f2-6ca1f656aabc",
          properties: {},
          validations: {
            required: false,
          },
          type: "short_text",
        },
      ],
    },
    type: "group",
  },
  {
    id: "JKFkuCeWeu3u",
    title: "Which plan would you prefer? ",
    ref: "ad022558-fadc-4e6f-b706-20b46996f0ee",
    properties: {
      description:
        "You can view the different options here:\n[after.com/prearrange-pricing](https://www.after.com/prearrange-pricing)",
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "Mmgav096WYiN",
          ref: "742846ca-b8d1-4a4e-8506-1b43ae3e1433",
          label: "Base",
        },
        {
          id: "Ujcnat1PmJNC",
          ref: "28fd20b6-a442-447d-a984-f09988fcbf1a",
          label: "Crest",
        },
        {
          id: "XGlibk6VjLor",
          ref: "74291222-7562-41a3-8c2c-65e2328580ea",
          label: "Summit",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "5ZCpmj4WmEOY",
    title:
      "Do you have a pre-existing life insurance policy or annuity contract?",
    ref: "d784250a-58bb-42d8-824e-af0581a2dff4",
    properties: {},
    validations: {
      required: false,
    },
    type: "yes_no",
  },
  {
    id: "uRJjjutAfQ79",
    title:
      "Will the insurance applied for replace or change any insurance or annuity now or recently in force?",
    ref: "649cc527-c338-431f-9c89-0039cc2b9e77",
    properties: {
      description:
        "It is highly unusual for this to be the case - please indicate 'No' unless you are confident otherwise.",
    },
    validations: {
      required: false,
    },
    type: "yes_no",
  },
  {
    id: "gKBOHulluUBC",
    title: "Do you have an existing Will or Estate Plan?",
    ref: "a8b3056f-6dfc-4b61-beb0-67cf10c73494",
    properties: {},
    validations: {
      required: false,
    },
    type: "yes_no",
  },
  {
    id: "PdSkPcYJ9MP6",
    title:
      "Have {{var:subject_2}} been diagnosed by a physician with a terminal illness or are {{var:subject_2}} currently receiving hospice care? ",
    ref: "e846dd7b-5566-4c72-aa27-b38247cdb617",
    properties: {},
    validations: {
      required: true,
    },
    type: "yes_no",
  },
  {
    id: "8AHYTvRewphI",
    title: "Payment Period",
    ref: "f9986da3-3eb4-4bb2-a4c7-4012dbd23b6c",
    properties: {
      description: "How would you like to pay for your policy? ",
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "w21LZ8EPHzm2",
          ref: "9395445e-a936-470e-a748-d95b205526bc",
          label: "Single Pay - upfront",
        },
        {
          id: "iHQsqOqmokqL",
          ref: "a6352aff-84c2-47bf-8a78-90a91378464b",
          label: "3 Year Pay - with $495 down payment",
        },
        {
          id: "ugkMvuMRfJuP",
          ref: "78725ae3-c75d-459e-8738-6da43b8b8067",
          label: "3 Year Pay - without $495 down payment",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "mrxCaB67ESLt",
    title: "Payment Period",
    ref: "946a4f51-4b71-4931-a345-c591b0b7810e",
    properties: {
      description: "How would you like to pay for your policy? ",
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "ut5N3w6pRO2n",
          ref: "828a62f8-034f-4cc4-bc4a-e2887f8e4351",
          label: "Single Pay - upfront",
        },
        {
          id: "kmcGwhmQSiSg",
          ref: "b5f278ab-100d-4ea2-93ce-09505230a0f8",
          label: "3 Year Pay - monthly payments",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "FRurrhgwAM4p",
    title:
      "During the past two years have you/they been advised by a medical professional to have any surgical procedure that has not been performed?; or have you/they been treated or are you/they being treated (including medication) by a medical professional for any of the following diseases or disorders:",
    ref: "f4c6de59-92ea-4185-8a9c-7492bc0730ee",
    properties: {
      description:
        "COPD, Emphysema, ALS, Cirrhosis of the Liver, Drug or Alcohol Dependency, Kidney failure (including dialysis), Congestive Heart Failure Heart Disease, Stroke, Cancer (other than skin), Diabetic Coma/Insulin Shock, Amputation (due to disease), Alzheimer’s/Dementia, or an Immune System Disorder",
    },
    validations: {
      required: true,
    },
    type: "yes_no",
  },
  {
    id: "H2hkIGpWU9qC",
    title:
      "Within the past one (1) year, have {{var:subject_2}} had, been diagnosed with, or received treatment (including medication) by a medical professional for any of the following:\n\nCOPD (Chronic Obstructive Pulmonary Disease), emphysema, chronic heart disease, congestive heart failure, or cirrhosis of the liver, kidney/renal failure,kidney dialysis, Alzheimer’s disease, ALS (Amyotrophic Lateral Sclerosis) or amputation due to disease?",
    ref: "e8f9a43b-592e-408a-a785-9669fee6c802",
    properties: {},
    validations: {
      required: true,
    },
    type: "yes_no",
  },
  {
    id: "C9hcDanGQP8z",
    title:
      "Within the past two (2) years, have {{var:subject_2}} had, been diagnosed with, or received treatment (including medication) by a medical professional for any of the following:\n\nStroke, organ transplant, cancer (other than skin), or an immune system disorder?",
    ref: "8100884a-1e32-4d21-84c6-39b04e2ebea1",
    properties: {},
    validations: {
      required: true,
    },
    type: "yes_no",
  },
  {
    id: "0R4Z607Sp9zy",
    title:
      "Within the past five (5) years, have {{var:subject_2}} had, been diagnosed with, or received treatment (including medication) by a medical professional for any of the following:\n\ncancer; congestive heart failure; stroke; COPD; Alzheimer’s/dementia; insulin-dependent diabetes; alcohol or drug dependency; AIDS or AIDS-related complex (ARC), or any disease or disorder of the heart, blood, brain, lung, kidney, liver, circulatory system, digestive system, or nervous system?",
    ref: "85d2518b-db4b-4739-a9f7-023bb07883be",
    properties: {},
    validations: {
      required: true,
    },
    type: "yes_no",
  },
  {
    id: "BJ2TW05VblE5",
    title: "What date of the month would you like payment to be drawn?",
    ref: "708c2165-dd1c-40cb-99d0-b92a99f93de6",
    properties: {
      description: "1st - 28th",
      randomize: false,
      alphabetical_order: false,
      options: [
        {
          id: "ORpqqlPX6Ty9",
          ref: "d1e8323b-ecb5-4ab3-9eae-27d806257967",
          label: "1",
        },
        {
          id: "GUXFnEFbTrWs",
          ref: "a986580c-5e2b-40f9-a1b1-0c8127b07c71",
          label: "2",
        },
        {
          id: "c2Cg1cd1VM5s",
          ref: "b738951b-9867-481e-bd97-1ac897695c3d",
          label: "3",
        },
        {
          id: "UrxXVgcuXjbP",
          ref: "33bf8640-18f6-4700-81c5-facc8fcce763",
          label: "4",
        },
        {
          id: "dlAIny0HTOaS",
          ref: "3b40fc84-db5b-44b9-a89c-2ad659ae4cfc",
          label: "5",
        },
        {
          id: "TIRxQfsmOZMS",
          ref: "b641227c-9026-4884-ada5-8e3b31aecaa3",
          label: "6",
        },
        {
          id: "nzOhQFT4kxYP",
          ref: "55e8a262-0228-4029-bdb8-ec0dbbecee70",
          label: "7",
        },
        {
          id: "83KgRQq0dneT",
          ref: "c79a77f5-d1f0-4982-89ee-18fc4f5da054",
          label: "8",
        },
        {
          id: "CZILMPX7msDf",
          ref: "bcea396e-d3a8-4903-8015-95fec188a717",
          label: "9",
        },
        {
          id: "AU40wjlnplub",
          ref: "3a196936-b3a4-4a7c-8a42-d1889818c626",
          label: "10",
        },
        {
          id: "COf51tnpK3mp",
          ref: "6a619c14-682d-4540-8ab3-87d72aaa1e5b",
          label: "11",
        },
        {
          id: "kWDF4Y9sSvy9",
          ref: "bb8166f5-71e1-4428-ad26-2d12ad6cd1a0",
          label: "12",
        },
        {
          id: "KwkmazdNoOSI",
          ref: "e77e4300-1239-4083-9ad2-e0317b7a8464",
          label: "13",
        },
        {
          id: "7qTaxCB6w5Gz",
          ref: "db7e1d26-489c-4fd4-9349-fb8d904a78a3",
          label: "14",
        },
        {
          id: "i20Jqu2Bb4qA",
          ref: "7cd3dc89-d139-4970-a173-5ac15a2c3d31",
          label: "15",
        },
        {
          id: "fSvCdjU1YRIr",
          ref: "f5e7962b-c4a1-4894-ac2d-b82e993cabcc",
          label: "16",
        },
        {
          id: "PlsWuAqeUSi9",
          ref: "ec4d3a11-0966-41dd-bc7a-2e143703955f",
          label: "17",
        },
        {
          id: "telWI9IlcfrV",
          ref: "95423ee2-5f89-429b-b979-b983042cd323",
          label: "18",
        },
        {
          id: "mDmolxOWrRzd",
          ref: "d1c21206-c045-46fe-a7c2-c88b016328bc",
          label: "19",
        },
        {
          id: "C8HkoTAHMmNJ",
          ref: "057d4676-ba92-4a28-b9fa-e688841f3966",
          label: "20",
        },
        {
          id: "Acwwk2I69lYI",
          ref: "8d051cc3-a051-41ca-93b7-074a199b1249",
          label: "21",
        },
        {
          id: "d9dZsj3DSqyN",
          ref: "ad40e674-ebef-49ee-bb7f-e9a08d968655",
          label: "22",
        },
        {
          id: "5HEZByR8jIhY",
          ref: "8103142f-b45c-4751-8b37-92232be619e5",
          label: "23",
        },
        {
          id: "8F2VrKmbTQsS",
          ref: "0717a300-ed38-4193-bf4f-629318ac7555",
          label: "24",
        },
        {
          id: "TcYwJ3kNaLhA",
          ref: "a648727d-1fe1-40aa-8738-83995bd6a814",
          label: "25",
        },
        {
          id: "SkbWVAl7Fuao",
          ref: "73f6e6d4-b19f-4ad6-995d-4f81dd4ff2b3",
          label: "26",
        },
        {
          id: "NlDneS3GZAPi",
          ref: "56a6e6e4-1504-4939-ab02-e7e7e03136be",
          label: "27",
        },
        {
          id: "Avn7wpgZnutc",
          ref: "54bd463a-30b5-45d3-a9d2-28a08c502646",
          label: "28",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "single_select",
  },
  {
    id: "tfyZh7pk29Us",
    title: "Payment Option ",
    ref: "a1d792cf-b201-4b05-90a0-873a325aab80",
    properties: {
      description:
        "Based on your response, we are unable to offer payment plans. However, an annuity is available.",
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "oPFEOZD6KPaB",
          ref: "e6f93a11-7a2c-43f9-a700-47ada032bef5",
          label: "Single Pay - Annuity",
        },
      ],
    },
    validations: {
      required: false,
    },
    type: "multiple_select",
  },
  {
    id: "VgVTYUp14KZt",
    title: "Preferred method of payment",
    ref: "57d9d2a8-9af3-44dc-be4f-c1ffd4d7078a",
    properties: {
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "zMpD6aqBoz8e",
          ref: "c3df800d-1505-4a5c-8618-ba55fa943476",
          label: "Debit/Credit card",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "B8a1g4HAC5rN",
    title: "Preferred method of payment",
    ref: "095d07b8-dea5-4a21-8f96-47a119b3ef92",
    properties: {
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: false,
      vertical_alignment: true,
      options: [
        {
          id: "nkIRF8AdJpma",
          ref: "02db40b1-ee7c-4639-b639-1dfe056f07ec",
          label: "Debit/Credit card",
        },
        {
          id: "VZelsTVM1A95",
          ref: "6ae18439-40e6-4f7a-928f-f67851b4aca2",
          label: "Check",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "HssiHR4f8DfC",
    title: "How did you hear about us?",
    ref: "9023f914-c963-4bc6-aa93-1c1e5811215e",
    properties: {
      randomize: false,
      allow_multiple_selection: false,
      allow_other_choice: true,
      vertical_alignment: true,
      options: [
        {
          id: "28UCO8OnkANR",
          ref: "314d0539-d30c-434f-a06a-2aba2ac380e7",
          label: "Google",
        },
        {
          id: "tXUA465BaWz2",
          ref: "f13c3ae0-919e-460d-b19f-5532f6cd6be8",
          label: "Facebook",
        },
        {
          id: "6h9Fve0Es223",
          ref: "f7177d24-6f2b-454e-8b93-cfca51e7325c",
          label: "Instagram",
        },
        {
          id: "66nGd9kUqJMv",
          ref: "94338b99-25b6-44bc-bca3-1085c6307615",
          label: "Referral",
        },
      ],
    },
    validations: {
      required: true,
    },
    type: "multiple_select",
  },
  {
    id: "VNQ7ld4aXZRp",
    title: "Who referred you to us? ",
    ref: "176b6dd6-6b25-41df-9219-56c5d9f4f454",
    properties: {
      description:
        "Must provide a verified customer to receive the referral bonus ",
    },
    validations: {
      required: false,
    },
    type: "short_text",
  },
];
