import type { Config } from "config";

const config: Config = {
  environment: "prod",
  debug: false,
  testing: false,
  sentryDSN:
    "https://7def4bec172f45b6ba0e5abcd53d1347@o1399750.ingest.sentry.io/4503932540813312",
  apiEndpoint: "https://api.after.com",
  googleMapsApiKey: "AIzaSyBDvyeYDq-0jNVNUbFf7j2vPImKpTfVmMU",
  firebaseConfig: {
    apiKey: "AIzaSyDWcFbCWJKNt4MZifFYz4w5qhgSXkXe3xQ",
    authDomain: "prj-aft-p-main-75d6.firebaseapp.com",
    projectId: "prj-aft-p-main-75d6",
    storageBucket: "prj-aft-p-main-75d6.appspot.com",
    messagingSenderId: "465728175739",
    appId: "1:465728175739:web:4500fda2ab1193bc896cde",
    measurementId: "G-39K4XKJFE3",
  },
};

export default config;
