import { useLocation } from "react-router-dom";

import Logo from "svg/logo-with-type.svg";
import {
  OnboardingContainer,
  OnboardingContent,
  OnboardingPanel,
  OnboardingPanelUnder,
  OnboardingHeader,
} from "layouts/OnboardingLayout";
import Form from "components/Form";
import { FormConfig, FieldType } from "components/Form/types";
import { formFields } from "./form";

const formConfig: FormConfig = {
  title: "Pre-Need Application",
  id: "pre_need_application",
  fields: formFields,
};

export default function QuoteOnboarding() {
  const location = useLocation();
  console.log(location);

  const handleSubmit = (formData: Record<string, any>) => {
    console.log("Form submitted with data:", formData);
  };

  return (
    <OnboardingContainer>
      <OnboardingHeader className="flex justify-center sm:mt-8 sm:text-3xl">
        <Logo className="w-28" />
      </OnboardingHeader>

      <div className="">
        <OnboardingContent className="w-full">
          <div className="max-w-lg mx-auto">
            <Form
              formConfig={formConfig}
              onSubmit={handleSubmit}
              responseId={"test"}
            />
          </div>
        </OnboardingContent>
      </div>
    </OnboardingContainer>
  );
}
