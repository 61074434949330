import React from "react";
import { useLocation } from "react-router-dom";

interface ABSplitProps {
  /** The name of the query parameter to check */
  queryParam: string;
  /** The value to match against the query parameter */
  matchValue: string;
  /** Component to render when the query parameter does not match */
  ComponentA: React.ComponentType<any>;
  /** Component to render when the query parameter matches the matchValue */
  ComponentB: React.ComponentType<any>;
}

/**
 * A component that renders one of two components based on a URL query parameter.
 */
const ABSplit: React.FC<ABSplitProps> = ({
  queryParam,
  matchValue,
  ComponentA,
  ComponentB,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get(queryParam);

  if (paramValue === matchValue) {
    return <ComponentB />;
  } else {
    return <ComponentA />;
  }
};

export default ABSplit;
