import React from "react";

import { useFormTextSubstitution } from "components/Form/hooks";
import { Screen } from "./types";

interface EndScreenProps {
  screen: Screen;
  response?: any;
  error?: any;
}

const EndScreen: React.FC<EndScreenProps> = ({ screen, response, error }) => {
  const { substituteVariables } = useFormTextSubstitution();

  const title = substituteVariables(screen.title);
  const description = substituteVariables(screen.properties?.description || "");

  return (
    <div className="text-center">
      <h1 className="whitespace-pre-line mb-6">{title}</h1>
      {description && <p className="whitespace-pre-line">{description}</p>}
    </div>
  );
};

export default EndScreen;
