import React from "react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";

// TODO Load this config from API

const PlanComparisonTable = () => {
  const plans = {
    base: {
      label: "Base",
      features: {
        documents: true,
        transport: true,
        cremation: true,
        delivery: true,
        priceLock: false,
        travelProtection: false,
        urnDiscount: false,
        deathCertificates: false,
        estateGuide: false,
        griefCourse: false,
        nationwideDelivery: false,
        estateConsultation: false,
        griefCounseling: false,
        memorialTree: false,
      },
    },
    crest: {
      label: "Crest",
      features: {
        documents: true,
        transport: true,
        cremation: true,
        delivery: true,
        priceLock: true,
        travelProtection: true,
        urnDiscount: "$150",
        deathCertificates: 1,
        estateGuide: true,
        griefCourse: true,
        nationwideDelivery: false,
        estateConsultation: false,
        griefCounseling: false,
        memorialTree: false,
      },
    },
    summit: {
      label: "Summit",
      features: {
        documents: true,
        transport: true,
        cremation: true,
        delivery: true,
        priceLock: true,
        travelProtection: true,
        urnDiscount: "$250",
        deathCertificates: 3,
        estateGuide: true,
        griefCourse: true,
        nationwideDelivery: true,
        estateConsultation: true,
        griefCounseling: true,
        memorialTree: true,
      },
    },
  };

  const allFeatures = [
    {
      label: "Permits, filing documents, and obituary listing",
      key: "documents",
    },
    {
      label: "Transportation and secure storage of decedent",
      key: "transport",
    },
    {
      label: "Honorable cremation, cremation container and scattering urn",
      key: "cremation",
    },
    { label: "Local hand delivery of remains", key: "delivery" },
    {
      label: "Price lock guarantee",
      key: "priceLock",
      callout:
        "Inflation protection guarantee. No additional costs, no matter what.",
    },
    {
      label: "Travel protection plan",
      key: "travelProtection",
      callout:
        "Covers transportation outside of 75 miles of your home. Includes domestic and international coverage.",
    },
    { label: "Urn discount", key: "urnDiscount" },
    { label: "Certified death certificates", key: "deathCertificates" },
    { label: "Post-loss family estate guide", key: "estateGuide" },
    { label: "Online grief course", key: "griefCourse" },
    { label: "Nationwide delivery of remains", key: "nationwideDelivery" },
    {
      label: "Professional estate settlement consultation",
      key: "estateConsultation",
    },
    {
      label: "Online grief counseling / Ask a therapist",
      key: "griefCounseling",
      callout: "Two free online sessions with a licensed grief counselor.",
    },
    { label: "Memorial tree planted", key: "memorialTree" },
  ];

  const renderFeature = (featureKey, planKey) => {
    const featureValue = plans[planKey].features[featureKey];
    if (featureValue === true) {
      return <CheckIcon className="h-5 w-5 text-green-500 mx-auto" />;
    } else if (featureValue === false) {
      return <XMarkIcon className="h-5 w-5 text-red-500 mx-auto" />;
    } else {
      return <span className="text-gray-600 mx-auto">{featureValue}</span>;
    }
  };

  const renderCallout = (callout) => {
    if (!callout) return null;
    return (
      <div className="text-xs text-gray-500 mt-1 flex items-center space-x-1">
        <span>{callout}</span>
      </div>
    );
  };

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full align-middle">
        <div className="">
          <table className="min-w-full divide-y divide-oat-400">
            <thead className="">
              <tr>
                <th
                  scope="col"
                  className="pl-4 pr-3 text-left text-sm font-normal text-gray-600 sm:pl-6"
                ></th>
                {Object.keys(plans).map((key) => (
                  <th
                    key={key}
                    scope="col"
                    className="px-2 py-2 text-left text-sm font-normal text-gray-600 text-center"
                  >
                    {plans[key].label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {allFeatures.map((feature, index) => (
                <tr key={index} className="even:bg-oat-50">
                  <td className="px-4 py-2 text-sm text-gray-600 sm:pl-6">
                    {feature.label}
                    {renderCallout(feature.callout)}
                  </td>
                  {Object.keys(plans).map((key) => (
                    <td
                      key={key}
                      className="px-2 py-2 text-sm text-gray-600 text-center"
                    >
                      {renderFeature(feature.key, key)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PlanComparisonTable;
