import React, { useState, useEffect, useRef, RefObject } from "react";
import { Controller, Control, useFormContext } from "react-hook-form";
import mergeRefs from "merge-refs";
import {
  ComboBox,
  Group,
  Input,
  ListBox,
  ListBoxItem,
  Header,
  Popover,
  Button,
} from "react-aria-components";
import { Loader } from "@googlemaps/js-api-loader";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { FieldConfig } from "components/Form/types";
import GoogleLogo from "svg/google-logo.svg";
import config from "config";
import statesData from "./statesData";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type LocationFieldProps = {
  field: FieldConfig;
  control: Control;
};

type Suggestion = {
  description: string;
  place_id: string;
};

const LocationField: React.FC<LocationFieldProps> = ({ field, control }) => {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();

  const isRequired = field.validations?.required || false;

  // State variables
  const [country, setCountry] = useState("United States");
  const [manualEntry, setManualEntry] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [showClear, setShowClear] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [hasRevealedFields, setHasRevealedFields] = useState(false); // Tracks if fields have been revealed
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );
  const [debounceTimer, setDebounceTimer] = useState<number | null>(null);

  // Ref for Address Line 1 Input
  const addressInputRef = useRef<HTMLInputElement | null>(null);
  const addressAutocompleteContainer = useRef<HTMLDivElement | null>(null);

  // Load Google Maps API
  useEffect(() => {
    const loader = new Loader({
      apiKey: config.googleMapsApiKey,
      libraries: ["places"],
    });

    loader.load().then(() => {
      setApiLoaded(true);
      autocompleteServiceRef.current =
        new google.maps.places.AutocompleteService();
      placesServiceRef.current = new google.maps.places.PlacesService(
        document.createElement("div")
      );
    });
  }, []);

  // Define field names using the ref as a prefix
  const prefix = field.ref;
  const streetFullName = `${prefix}.street`; // Hidden field combining street_1 and street_2
  const street1Name = `${prefix}.address_line_1`;
  const street2Name = `${prefix}.address_line_2`;
  const cityName = `${prefix}.city`;
  const zipName = `${prefix}.zip`;
  const stateName = `${prefix}.state`;
  const countryName = `${prefix}.country`;

  // Watch the field values to trigger re-renders
  const street1Value = watch(street1Name);
  const street2Value = watch(street2Name);
  const cityValue = watch(cityName);
  const zipValue = watch(zipName);
  const stateValue = watch(stateName);
  const countryValue = watch(countryName);

  // Show Clear button if any address field has a value
  useEffect(() => {
    const anyFieldHasValue =
      street1Value ||
      street2Value ||
      cityValue ||
      zipValue ||
      stateValue ||
      (countryValue && countryValue !== "United States");

    setShowClear(Boolean(anyFieldHasValue));
  }, [
    street1Value,
    street2Value,
    cityValue,
    zipValue,
    stateValue,
    countryValue,
  ]);

  // Reveal additional fields on initial mount if any address field has a value
  useEffect(() => {
    const anyFieldHasValue =
      getValues(street1Name) ||
      getValues(street2Name) ||
      getValues(cityName) ||
      getValues(zipName) ||
      getValues(stateName) ||
      (getValues(countryName) && getValues(countryName) !== "United States");

    if (anyFieldHasValue && !hasRevealedFields) {
      setShowAdditionalFields(true);
      setHasRevealedFields(true);
    }
    // Empty dependency array ensures this runs only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Combine street1 and street2 into streetFullName
  useEffect(() => {
    const fullStreet = [street1Value, street2Value].filter(Boolean).join(" ");
    setValue(streetFullName, fullStreet);
  }, [street1Value, street2Value, setValue, streetFullName]);

  // Custom validation to ensure all required fields are filled when any field is filled
  useEffect(() => {
    const validateFields = async () => {
      if (
        isRequired ||
        street1Value ||
        street2Value ||
        cityValue ||
        zipValue ||
        stateValue
      ) {
        let hasError = false;

        // Street Address
        if (isRequired || street1Value) {
          if (!street1Value) {
            setError(street1Name, {
              type: "required",
              message: "Street Address is required.",
            });
            hasError = true;
          } else {
            clearErrors(street1Name);
          }
        }

        // City
        if (isRequired || cityValue) {
          if (!cityValue) {
            setError(cityName, {
              type: "required",
              message: "City is required.",
            });
            hasError = true;
          } else {
            clearErrors(cityName);
          }
        }

        // ZIP Code
        if (isRequired || zipValue) {
          if (!zipValue) {
            setError(zipName, {
              type: "required",
              message: "ZIP code is required.",
            });
            hasError = true;
          } else if (!/^\d{5}$/.test(zipValue)) {
            setError(zipName, {
              type: "pattern",
              message: "ZIP code must be exactly 5 digits.",
            });
            hasError = true;
          } else {
            clearErrors(zipName);
          }
        }

        // State
        if (isRequired || stateValue) {
          if (!stateValue) {
            setError(stateName, {
              type: "required",
              message: "State is required.",
            });
            hasError = true;
          } else {
            clearErrors(stateName);
          }
        }

        if (hasError) {
          setError(prefix, {
            type: "manual",
            message: "Address is incomplete.",
          });
        } else {
          clearErrors(prefix);
        }
      }
    };

    validateFields();
  }, [
    street1Value,
    street2Value,
    cityValue,
    zipValue,
    stateValue,
    isRequired,
    setError,
    clearErrors,
    prefix,
    street1Name,
    cityName,
    zipName,
    stateName,
  ]);

  // Handle Address Line 1 input changes
  const handleAddressLine1Change = (value: string) => {
    setValue(street1Name, value);

    if (manualEntry || !apiLoaded) return;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    if (value.length >= 4) {
      const timer = window.setTimeout(() => {
        autocompleteServiceRef.current?.getPlacePredictions(
          { input: value, componentRestrictions: { country: "us" } },
          (predictions, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              setSuggestions(predictions.slice(0, 5));
            } else {
              setSuggestions([]);
            }
          }
        );
      }, 500); // Debounce delay

      setDebounceTimer(timer);
    } else {
      setSuggestions([]);
    }
  };

  // Handle suggestion selection
  const handleSelectSuggestion = (description: string, placeId: string) => {
    setSuggestions([]);
    setShowAdditionalFields(true);
    setHasRevealedFields(true);

    // Fetch place details
    placesServiceRef.current?.getDetails(
      { placeId, fields: ["address_components"] },
      (place, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place?.address_components
        ) {
          const components = place.address_components;

          const streetNumberComponent = components.find((c) =>
            c.types.includes("street_number")
          );
          const routeComponent = components.find((c) =>
            c.types.includes("route")
          );
          const cityComponent = components.find((c) =>
            c.types.includes("locality")
          );
          const zipComponent = components.find((c) =>
            c.types.includes("postal_code")
          );
          const stateComponent = components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );

          if (streetNumberComponent && routeComponent) {
            const street1Value = `${streetNumberComponent.long_name} ${routeComponent.long_name}`;
            setValue(street1Name, street1Value);
          }
          if (cityComponent) {
            setValue(cityName, cityComponent.long_name);
          }
          if (zipComponent) {
            setValue(zipName, zipComponent.long_name);
          }
          if (stateComponent) {
            const stateLongName = stateComponent.long_name;
            // Match the state to the statesData array
            const matchedState = statesData.find(
              (state) => state.toLowerCase() === stateLongName.toLowerCase()
            );
            if (matchedState) {
              setValue(stateName, matchedState);
            } else {
              setValue(stateName, "");
            }
          }
        }
      }
    );
  };

  // Handle manual entry toggle
  const handleManualEntry = () => {
    setManualEntry(true);
    setSuggestions([]);
    setShowAdditionalFields(true);
    setHasRevealedFields(true);
  };

  // Handle clear functionality
  const handleClear = () => {
    setValue(street1Name, "");
    setValue(street2Name, "");
    setValue(cityName, "");
    setValue(zipName, "");
    setValue(stateName, "");
    setSuggestions([]);
    // Keep the additional fields visible if they have been revealed
    setShowClear(false);
  };

  const usStates = statesData;

  return (
    <div className="relative">
      <FieldLabel text={field.title} />
      <FieldDescription content={field.properties?.description} />

      <Controller
        name={countryName}
        control={control}
        defaultValue={country} // Set defaultValue to "United States"
        render={({ field: countryField }) => (
          <select
            {...countryField}
            value={countryField.value}
            onChange={(e) => {
              countryField.onChange(e);
              setCountry(e.target.value);
            }}
            className="w-full mb-2 p-2 border rounded h-10 focus:outline-cantelope focus:outline-2 focus:outline"
          >
            {/* Populate with country options as needed */}
            <option value="United States">United States</option>
            {/* Add other countries if needed */}
          </select>
        )}
      />

      {/* Address Line 1 */}
      <div className="relative mb-2" ref={addressAutocompleteContainer}>
        <Controller
          name={street1Name}
          control={control}
          defaultValue=""
          rules={{
            required: isRequired ? "Street Address is required." : false,
          }}
          render={({ field: street1Field }) => (
            <>
              <Input
                {...street1Field}
                ref={mergeRefs(addressInputRef, street1Field.ref)}
                type="text"
                placeholder="Address Line 1"
                className="w-full p-2 border rounded focus:outline-cantelope focus:outline-2 focus:outline"
                onChange={(e) => {
                  street1Field.onChange(e.target.value);
                  handleAddressLine1Change(e.target.value);
                }}
                value={street1Field.value || ""}
              />
              {showClear && (
                <Button
                  onPress={handleClear}
                  className="absolute right-1 top-1 text-sm text-neutral-500 focus:outline-cantelope focus:outline-2 focus:outline"
                >
                  Clear
                </Button>
              )}
              {errors[street1Name] && (
                <FieldError>{errors[street1Name]?.message}</FieldError>
              )}
            </>
          )}
        />

        {/* Enter Address Manually */}
        {!manualEntry && !showAdditionalFields && (
          <Button
            onPress={handleManualEntry}
            className="mb-2 px-0 text-sm text-neutral-500 underline decoration-dotted"
          >
            Enter address manually
          </Button>
        )}
      </div>

      {/* Autocomplete Suggestions */}
      {suggestions.length > 0 && addressInputRef.current && (
        <Popover
          triggerRef={addressInputRef as RefObject<Element>}
          UNSTABLE_portalContainer={addressAutocompleteContainer.current}
          isOpen={suggestions.length > 0}
          shouldFlip={false}
          className="w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out"
        >
          <ListBox
            aria-label="Address autocomplete options"
            selectionMode="single"
            onSelectionChange={(keys: Selection) => {
              const id = Array.from(keys)[0];
              const suggestion = suggestions.find((s) => s.place_id === id);

              if (suggestion) {
                handleSelectSuggestion(
                  suggestion.description,
                  suggestion.place_id
                );
              }
            }}
          >
            <Header className="p-2 w-full text-sm text-neutral-400 flex space-between items-center">
              <div className="flex items-end">
                <span className="inline-block mb-0.5">
                  Suggestions powered by
                </span>{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 inline-block"
                >
                  <GoogleLogo className="h-4 w-12" />
                </a>
              </div>

              <Button
                onPress={() => setSuggestions([])}
                className="p-1 ml-auto"
              >
                <XMarkIcon className="h-4" />
              </Button>
            </Header>
            <>
              {suggestions.map((suggestion) => (
                <ListBoxItem
                  key={suggestion.place_id}
                  id={suggestion.place_id}
                  value={suggestion}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                >
                  {suggestion.description}
                </ListBoxItem>
              ))}
            </>
          </ListBox>
        </Popover>
      )}

      {/* Additional Address Fields */}
      {showAdditionalFields && (
        <>
          {/* Address Line 2 */}
          <div className="relative mb-2">
            <Controller
              name={street2Name}
              control={control}
              defaultValue=""
              render={({ field: street2Field }) => (
                <>
                  <Input
                    {...street2Field}
                    type="text"
                    placeholder="Address Line 2"
                    className="w-full p-2 border rounded focus:outline-cantelope focus:outline-2 focus:outline"
                    onChange={(e) => street2Field.onChange(e.target.value)}
                    value={street2Field.value || ""}
                  />
                  {errors[street2Name] && (
                    <FieldError>{errors[street2Name]?.message}</FieldError>
                  )}
                </>
              )}
            />
          </div>

          {/* City and ZIP code */}
          <div className="flex mb-2 space-x-2">
            {/* City */}
            <div className="w-1/2 relative">
              <Controller
                name={cityName}
                control={control}
                defaultValue=""
                rules={{
                  required: isRequired ? "City is required." : false,
                }}
                render={({ field: cityField }) => (
                  <>
                    <Input
                      {...cityField}
                      type="text"
                      placeholder="City"
                      className="w-full p-2 border rounded focus:outline-cantelope focus:outline-2 focus:outline"
                      onChange={(e) => {
                        cityField.onChange(e.target.value);
                      }}
                      value={cityField.value || ""}
                    />
                    {errors[cityName] && (
                      <FieldError>{errors[cityName]?.message}</FieldError>
                    )}
                  </>
                )}
              />
            </div>

            {/* ZIP Code */}
            <div className="w-1/2 relative">
              <Controller
                name={zipName}
                control={control}
                defaultValue=""
                rules={{
                  required: isRequired ? "ZIP code is required." : false,
                  pattern: {
                    value: /^\d{5}$/,
                    message: "ZIP code must be exactly 5 digits.",
                  },
                }}
                render={({ field: zipField }) => (
                  <>
                    <Input
                      {...zipField}
                      type="text"
                      placeholder="ZIP Code"
                      className="w-full p-2 border rounded focus:outline-cantelope focus:outline-2 focus:outline"
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                        zipField.onChange(value.slice(0, 5)); // Limit to 5 digits
                      }}
                      value={zipField.value || ""}
                    />
                    {errors[zipName] && (
                      <FieldError>{errors[zipName]?.message}</FieldError>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          {/* State Dropdown */}
          <div className="relative mb-2">
            <Controller
              name={stateName}
              control={control}
              defaultValue=""
              rules={{
                required: isRequired ? "State is required." : false,
              }}
              render={({ field: stateField }) => (
                <>
                  <select
                    {...stateField}
                    value={stateField.value || ""}
                    onChange={(e) => stateField.onChange(e.target.value)}
                    className="w-full p-2 border rounded h-10 focus:outline-cantelope focus:outline-2 focus:outline"
                  >
                    <option value="">Select State</option>
                    {usStates.map((stateOption) => (
                      <option key={stateOption} value={stateOption}>
                        {stateOption}
                      </option>
                    ))}
                  </select>
                  {errors[stateName] && (
                    <FieldError>{errors[stateName]?.message}</FieldError>
                  )}
                </>
              )}
            />
          </div>
        </>
      )}

      {/* Hidden Street Full Name */}
      <Controller
        name={streetFullName}
        control={control}
        defaultValue=""
        render={({ field }) => <input type="hidden" {...field} />}
      />
    </div>
  );
};

export default LocationField;
